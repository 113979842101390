@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html,
#root,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  position: relative; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lujVj9_mf.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lujVj9_mf.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNK7lujVj9_mf.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3qO67lujVj9_mf.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3qN67lujVj9_mf.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lujVj9_mf.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"), url(https://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7lujVj9w.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

.Toolkit {
  padding: 20px 0; }
  .Toolkit__buttonShowCase {
    padding: 10px; }
  .Toolkit__inputShowCase > * {
    margin: 0 10px !important; }

.Loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 100;
  position: relative; }
  .Loader__text {
    bottom: 30%;
    position: absolute; }
  .Loader__animation {
    max-width: 30%; }
    .Loader__animation--empresas {
      color: #0093ca; }

@-webkit-keyframes empresas-svg-fill-letter {
  0% {
    fill: transparent; }
  50% {
    fill: black; }
  100% {
    fill: transparent; } }

@keyframes empresas-svg-fill-letter {
  0% {
    fill: transparent; }
  50% {
    fill: black; }
  100% {
    fill: transparent; } }
      .Loader__animation--empresas .svg-elem-1 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 2.7s both;
        animation: empresas-svg-fill-letter 2.7s linear infinite 2.7s both; }
      .Loader__animation--empresas .svg-elem-2 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.2s both;
        animation: empresas-svg-fill-letter 2.7s linear infinite 0.2s both; }
      .Loader__animation--empresas .svg-elem-3 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.30000000000000004s both;
        animation: empresas-svg-fill-letter 2.7s linear infinite 0.30000000000000004s both; }
      .Loader__animation--empresas .svg-elem-4 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.4s both;
        animation: empresas-svg-fill-letter 2.7s linear infinite 0.4s both; }
      .Loader__animation--empresas .svg-elem-5 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.5s both;
        animation: empresas-svg-fill-letter 2.7s linear infinite 0.5s both; }
      .Loader__animation--empresas .svg-elem-6 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.6s both;
        animation: empresas-svg-fill-letter 2.7s linear infinite 0.6s both; }
      .Loader__animation--empresas .svg-elem-7 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.7000000000000001s both;
        animation: empresas-svg-fill-letter 2.7s linear infinite 0.7000000000000001s both; }
      .Loader__animation--empresas .svg-elem-8 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.8s both;
        animation: empresas-svg-fill-letter 2.7s linear infinite 0.8s both; }
      .Loader__animation--empresas .svg-elem-9 {
        -webkit-animation: empresas-svg-fill-letter 2.7s linear infinite 0.9s both;
        animation: empresas-svg-fill-letter 2.7s linear infinite 0.9s both; }

@-webkit-keyframes empresas-svg-fill-2 {
  0% {
    fill: transparent; }
  50% {
    fill: #003a63; }
  100% {
    fill: transparent; } }

@keyframes empresas-svg-fill-2 {
  0% {
    fill: transparent; }
  50% {
    fill: #003a63; }
  100% {
    fill: transparent; } }
      .Loader__animation--empresas .svg-elem-10 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1s both;
        animation: empresas-svg-fill-2 2.7s linear infinite 1s both; }
      .Loader__animation--empresas .svg-elem-11 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.1s both;
        animation: empresas-svg-fill-2 2.7s linear infinite 1.1s both; }
      .Loader__animation--empresas .svg-elem-12 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.2000000000000002s both;
        animation: empresas-svg-fill-2 2.7s linear infinite 1.2000000000000002s both; }
      .Loader__animation--empresas .svg-elem-13 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.3000000000000003s both;
        animation: empresas-svg-fill-2 2.7s linear infinite 1.3000000000000003s both; }
      .Loader__animation--empresas .svg-elem-14 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.4000000000000001s both;
        animation: empresas-svg-fill-2 2.7s linear infinite 1.4000000000000001s both; }
      .Loader__animation--empresas .svg-elem-15 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.5000000000000002s both;
        animation: empresas-svg-fill-2 2.7s linear infinite 1.5000000000000002s both; }
      .Loader__animation--empresas .svg-elem-16 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.6s both;
        animation: empresas-svg-fill-2 2.7s linear infinite 1.6s both; }
      .Loader__animation--empresas .svg-elem-17 {
        -webkit-animation: empresas-svg-fill-2 2.7s linear infinite 1.7000000000000002s both;
        animation: empresas-svg-fill-2 2.7s linear infinite 1.7000000000000002s both; }

@-webkit-keyframes empresas-svg-fill-3 {
  0% {
    fill: transparent; }
  50% {
    fill: #fefefe; }
  100% {
    fill: transparent; } }

@keyframes empresas-svg-fill-3 {
  0% {
    fill: transparent; }
  50% {
    fill: #fefefe; }
  100% {
    fill: transparent; } }
      .Loader__animation--empresas .svg-elem-18 {
        -webkit-animation: empresas-svg-fill-3 2.7s linear infinite 1.8000000000000003s both;
        animation: empresas-svg-fill-3 2.7s linear infinite 1.8000000000000003s both; }
      .Loader__animation--empresas .svg-elem-19 {
        -webkit-animation: empresas-svg-fill-3 2.7s linear infinite 1.9000000000000001s both;
        animation: empresas-svg-fill-3 2.7s linear infinite 1.9000000000000001s both; }
      .Loader__animation--empresas .svg-elem-20 {
        -webkit-animation: empresas-svg-fill-3 2.7s linear infinite 2s both;
        animation: empresas-svg-fill-3 2.7s linear infinite 2s both; }
      .Loader__animation--empresas .svg-elem-21 {
        -webkit-animation: empresas-svg-fill-3 2.7s linear infinite 2.1s both;
        animation: empresas-svg-fill-3 2.7s linear infinite 2.1s both; }
      .Loader__animation--empresas .svg-elem-22 {
        -webkit-animation: empresas-svg-fill-3 2.7s linear infinite 2.2s both;
        animation: empresas-svg-fill-3 2.7s linear infinite 2.2s both; }

@-webkit-keyframes empresas-svg-fill-4 {
  0% {
    fill: transparent; }
  50% {
    fill: #8ba5b7; }
  100% {
    fill: transparent; } }

@keyframes empresas-svg-fill-4 {
  0% {
    fill: transparent; }
  50% {
    fill: #8ba5b7; }
  100% {
    fill: transparent; } }
      .Loader__animation--empresas .svg-elem-23 {
        -webkit-animation: empresas-svg-fill-4 2.7s linear infinite 2.3000000000000003s both;
        animation: empresas-svg-fill-4 2.7s linear infinite 2.3000000000000003s both; }

@-webkit-keyframes empresas-svg-fill-5 {
  0% {
    fill: transparent; }
  50% {
    fill: #6588a0; }
  100% {
    fill: transparent; } }

@keyframes empresas-svg-fill-5 {
  0% {
    fill: transparent; }
  50% {
    fill: #6588a0; }
  100% {
    fill: transparent; } }
      .Loader__animation--empresas .svg-elem-24 {
        -webkit-animation: empresas-svg-fill-5 2.7s linear infinite 2.4000000000000004s both;
        animation: empresas-svg-fill-5 2.7s linear infinite 2.4000000000000004s both; }

@-webkit-keyframes empresas-svg-fill-6 {
  0% {
    fill: transparent; }
  50% {
    fill: #3f6b89; }
  100% {
    fill: transparent; } }

@keyframes empresas-svg-fill-6 {
  0% {
    fill: transparent; }
  50% {
    fill: #3f6b89; }
  100% {
    fill: transparent; } }
      .Loader__animation--empresas .svg-elem-25 {
        -webkit-animation: empresas-svg-fill-6 2.7s linear infinite 2.5000000000000004s both;
        animation: empresas-svg-fill-6 2.7s linear infinite 2.5000000000000004s both; }

@-webkit-keyframes empresas-svg-fill-7 {
  0% {
    fill: transparent; }
  50% {
    fill: #184c71; }
  100% {
    fill: transparent; } }

@keyframes empresas-svg-fill-7 {
  0% {
    fill: transparent; }
  50% {
    fill: #184c71; }
  100% {
    fill: transparent; } }
      .Loader__animation--empresas .svg-elem-26 {
        -webkit-animation: empresas-svg-fill-7 2.7s linear infinite 2.6s both;
        animation: empresas-svg-fill-7 2.7s linear infinite 2.6s both; }

@-webkit-keyframes empresas-svg-fill-8 {
  0% {
    fill: transparent; }
  50% {
    fill: #003a63; }
  100% {
    fill: transparent; } }

@keyframes empresas-svg-fill-8 {
  0% {
    fill: transparent; }
  50% {
    fill: #003a63; }
  100% {
    fill: transparent; } }
      .Loader__animation--empresas .svg-elem-27 {
        -webkit-animation: empresas-svg-fill-8 2.7s linear infinite 2.7s both;
        animation: empresas-svg-fill-8 2.7s linear infinite 2.7s both; }
    .Loader__animation--socovesa {
      color: #53308f; }

@-webkit-keyframes socovesa-svg-square {
  0% {
    fill: transparent; }
  50% {
    fill: #4b318c; }
  100% {
    fill: transparent; } }

@keyframes socovesa-svg-square {
  0% {
    fill: transparent; }
  50% {
    fill: #4b318c; }
  100% {
    fill: transparent; } }
      .Loader__animation--socovesa .svg-elem-1 {
        -webkit-animation: socovesa-svg-square 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 0.8s both;
        animation: socovesa-svg-square 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 0.8s both; }

@-webkit-keyframes socovesa-svg-fill-letter {
  0% {
    fill: transparent; }
  50% {
    fill: #565a6c; }
  100% {
    fill: transparent; } }

@keyframes socovesa-svg-fill-letter {
  0% {
    fill: transparent; }
  50% {
    fill: #565a6c; }
  100% {
    fill: transparent; } }
      .Loader__animation--socovesa .svg-elem-2 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 0.9s both;
        animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 0.9s both; }
      .Loader__animation--socovesa .svg-elem-3 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1s both;
        animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1s both; }
      .Loader__animation--socovesa .svg-elem-4 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.1s both;
        animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.1s both; }
      .Loader__animation--socovesa .svg-elem-5 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.2s both;
        animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.2s both; }
      .Loader__animation--socovesa .svg-elem-6 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.3s both;
        animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.3s both; }
      .Loader__animation--socovesa .svg-elem-7 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.4s both;
        animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.4s both; }
      .Loader__animation--socovesa .svg-elem-8 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.5s both;
        animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.5s both; }
      .Loader__animation--socovesa .svg-elem-9 {
        -webkit-animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.6s both;
        animation: socovesa-svg-fill-letter 1.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite 1.6s both; }
    .Loader__animation--pilares {
      color: #ff6900; }

@-webkit-keyframes pilares-svg-fill-letter {
  0% {
    fill: transparent; }
  50% {
    fill: #ff6b00; }
  100% {
    fill: transparent; } }

@keyframes pilares-svg-fill-letter {
  0% {
    fill: transparent; }
  50% {
    fill: #ff6b00; }
  100% {
    fill: transparent; } }
      .Loader__animation--pilares .svg-elem-1 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.3s both;
        animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.3s both; }
      .Loader__animation--pilares .svg-elem-2 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.4s both;
        animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.4s both; }
      .Loader__animation--pilares .svg-elem-3 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.5s both;
        animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.5s both; }
      .Loader__animation--pilares .svg-elem-4 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.6s both;
        animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.6s both; }
      .Loader__animation--pilares .svg-elem-5 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.7s both;
        animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.7s both; }
      .Loader__animation--pilares .svg-elem-6 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.8s both;
        animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.8s both; }
      .Loader__animation--pilares .svg-elem-7 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.9s both;
        animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.9s both; }
      .Loader__animation--pilares .svg-elem-8 {
        -webkit-animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 1s both;
        animation: pilares-svg-fill-letter 1s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 1s both; }
    .Loader__animation--almagro {
      color: #E52713; }

@-webkit-keyframes almagro-svg-fill-letter {
  0% {
    fill: transparent; }
  50% {
    fill: white; }
  100% {
    fill: transparent; } }

@keyframes almagro-svg-fill-letter {
  0% {
    fill: transparent; }
  50% {
    fill: white; }
  100% {
    fill: transparent; } }

@-webkit-keyframes almagro-svg-fill-square {
  0% {
    fill: transparent; }
  50% {
    fill: #ee3424; }
  100% {
    fill: transparent; } }

@keyframes almagro-svg-fill-square {
  0% {
    fill: transparent; }
  50% {
    fill: #ee3424; }
  100% {
    fill: transparent; } }
      .Loader__animation--almagro .svg-elem-square {
        -webkit-animation: almagro-svg-fill-square 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0s both;
        animation: almagro-svg-fill-square 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0s both; }
      .Loader__animation--almagro .svg-elem-1 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.3s both;
        animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.3s both; }
      .Loader__animation--almagro .svg-elem-2 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.4s both;
        animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.4s both; }
      .Loader__animation--almagro .svg-elem-3 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.5s both;
        animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.5s both; }
      .Loader__animation--almagro .svg-elem-4 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.6s both;
        animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.6s both; }
      .Loader__animation--almagro .svg-elem-5 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.7s both;
        animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.7s both; }
      .Loader__animation--almagro .svg-elem-6 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.8s both;
        animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.8s both; }
      .Loader__animation--almagro .svg-elem-7 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.9s both;
        animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 0.9s both; }
      .Loader__animation--almagro .svg-elem-8 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 1s both;
        animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 1s both; }
      .Loader__animation--almagro .svg-elem-9 {
        -webkit-animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 1.1s both;
        animation: almagro-svg-fill-letter 2s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite 1.1s both; }

@media only screen and (max-width: 768px) {
  .Loader__animation {
    max-width: 70%; }
    .Loader__animation--almagro {
      width: 30%; } }

.Login {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 80px 0px;
  margin: 0 0px 40px;
  height: 100%; }
  .Login__background {
    height: 100%; }
    .Login__background__image {
      width: 100%;
      height: 100%; }
  .Login__logo {
    display: block;
    margin: 0 auto; }
    .Login__logo--almagro {
      padding: 80px 0 10%; }
    .Login__logo--socovesa {
      padding: 80px 0 20%; }
    .Login__logo--pilares {
      padding: 80px 0 20%; }
    .Login__logo--empresas {
      padding: 80px 0 20%; }
  .Login__title {
    font-size: 32px;
    margin-bottom: 0;
    font-weight: 300; }
    .Login__title--almagro {
      color: #4D4D4D; }
    .Login__title--pilares {
      color: #ffffff; }
    .Login__title--socovesa {
      color: #ffffff; }
  .Login__subtitle {
    margin: 0 0 20px;
    font-size: 32px;
    font-weight: bold; }
  .Login--almagro {
    background-color: #FFFFFF;
    color: #4D4D4D; }
  .Login--pilares {
    background-color: #33383c;
    color: #ffffff; }
  .Login--socovesa {
    color: #53308f; }
  .Login--empresas {
    color: #003a63; }
  .Login__form__button {
    display: flex;
    margin: 24px 0;
    justify-content: center;
    align-content: center; }
    .Login__form__button > * {
      flex-basis: 50%; }

@media only screen and (max-width: 768px) {
  .Login {
    padding: 0 20px 60px;
    padding: 0 20px 60px; }
    .Login__title--almagro {
      color: white; }
    .Login--almagro {
      background: url(/static/media/video-almagro.e57834a3.gif) no-repeat center center fixed;
      background-size: cover;
      color: white; }
    .Login--pilares {
      color: #ffffff; }
    .Login--socovesa {
      color: #53308f; }
    .Login__background {
      display: none; } }

.ForgotPassword {
  display: flex;
  flex-direction: column;
  padding: 0 80px 0px;
  margin: 0 0px 40px;
  color: #303446;
  height: 100%; }
  .ForgotPassword--almagro {
    color: black;
    background-color: white; }
  .ForgotPassword__header {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 80px 0 150px;
    align-content: center;
    justify-content: center; }
    .ForgotPassword__header__arrow {
      display: flex;
      position: absolute;
      left: 0;
      justify-content: flex-start;
      align-self: center; }
    .ForgotPassword__header__logo {
      display: flex;
      justify-self: center;
      align-self: center; }
  .ForgotPassword__title {
    font-weight: 400; }
  .ForgotPassword__form {
    display: flex;
    flex-direction: column; }
    .ForgotPassword__form__buttons {
      display: flex;
      margin: 20px 0 0;
      align-content: center;
      justify-content: center; }
  .ForgotPassword__passwordSend {
    display: flex;
    flex-direction: column;
    text-align: center; }
    .ForgotPassword__passwordSend__header {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      margin: 0 0 20px; }
      .ForgotPassword__passwordSend__header__icon {
        display: flex;
        align-self: center;
        margin: 0 0 10px; }
    .ForgotPassword__passwordSend__span {
      color: inherit; }
      .ForgotPassword__passwordSend__span:last-of-type {
        margin-bottom: 30px; }

@media only screen and (max-width: 768px) {
  .ForgotPassword {
    padding: 0 20px 60px; } }

.RecoverPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  text-align: left;
  height: 100%; }
  .RecoverPassword--almagro {
    background-color: white;
    color: black; }
  .RecoverPassword__success {
    display: flex;
    flex-direction: column;
    text-align: center; }
    .RecoverPassword__success__icon {
      display: flex;
      align-self: center;
      justify-self: center;
      margin: 0 0 10px; }
    .RecoverPassword__success__span:last-of-type {
      margin: 0 0 30px; }
  .RecoverPassword__container {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .RecoverPassword__container__logo {
      padding: 80px 0 80px;
      display: block;
      margin: 0 auto; }
    .RecoverPassword__container__title {
      margin: 0; }
      .RecoverPassword__container__title:last-of-type {
        margin: 0 0 20px; }
    .RecoverPassword__container__form__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      justify-items: center;
      margin: 35px 0 0; }

@media only screen and (max-width: 768px) {
  .RecoverPassword {
    padding: 0 20px 60px; }
    .RecoverPassword__container__title {
      font-size: 24px; }
    .RecoverPassword__container__form__buttons {
      flex-direction: column; }
      .RecoverPassword__container__form__buttons > * {
        margin-bottom: 20px !important;
        width: 100% !important; } }

.MenuComponent {
  height: 100%; }
  .MenuComponent__Container__Section__name {
    color: '#546e7a';
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: 4px;
    padding: 0px 24px; }
    .MenuComponent__Container__Section__name--socovesa {
      color: #ffffff; }
    .MenuComponent__Container__Section__name--almagro {
      color: #546e7a; }
    .MenuComponent__Container__Section__name--pilares {
      color: #546e7a; }
    .MenuComponent__Container__Section__name--empresas {
      color: #546e7a; }
  .MenuComponent__Container__Section__itemList {
    list-style-type: none;
    padding: 0 0 60px; }
    .MenuComponent__Container__Section__itemList__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 10px;
      gap: 10px;
      margin: 0 0 20px;
      text-transform: uppercase;
      text-decoration: none;
      transition: 0.2s all ease;
      cursor: pointer;
      border-radius: 15.5px;
      padding: 7px 24px;
      outline: none; }
      .MenuComponent__Container__Section__itemList__item--socovesa {
        color: #ffffff; }
        .MenuComponent__Container__Section__itemList__item--socovesa.active {
          background-color: #53308f;
          color: #ffffff; }
      .MenuComponent__Container__Section__itemList__item--almagro {
        color: #4D4D4D; }
        .MenuComponent__Container__Section__itemList__item--almagro.active {
          background-color: #ee3424;
          color: #ffffff; }
      .MenuComponent__Container__Section__itemList__item--pilares {
        color: #ffffff; }
        .MenuComponent__Container__Section__itemList__item--pilares.active {
          background-color: #ff6900; }
      .MenuComponent__Container__Section__itemList__item--empresas {
        color: #b8b7ad; }
        .MenuComponent__Container__Section__itemList__item--empresas.active {
          color: #ffffff;
          background-color: #0093ca; }

@media only screen and (max-width: 768px) {
  .MenuComponent__Container__Section__name--socovesa {
    color: #546e7a; }
  .MenuComponent__Container__Section__itemList {
    padding: 0 0 30px; }
    .MenuComponent__Container__Section__itemList__item--socovesa {
      color: #546e7a; } }

.Navbar__desktop {
  display: flex;
  padding: 30px;
  flex-basis: 100%;
  background-color: inherit; }
  .Navbar__desktop--socovesa {
    border-bottom: 1px solid #d1d1d1; }
  .Navbar__desktop--almagro {
    border-bottom: 1px solid #d1d1d1; }
  .Navbar__desktop--pilares {
    border-bottom: 1px solid #d1d1d1; }
  .Navbar__desktop--empresas {
    border-bottom: 1px solid #d1d1d1; }
  .Navbar__desktop__date {
    display: inherit;
    justify-items: flex-start;
    letter-spacing: -0.02px; }
    .Navbar__desktop__date--socovesa {
      color: #303446; }
    .Navbar__desktop__date--almagro {
      color: #4D4D4D; }
    .Navbar__desktop__date--empresas {
      color: #003a63; }
  .Navbar__desktop__actions {
    display: flex;
    justify-items: flex-end;
    align-items: center;
    margin-left: auto; }
    .Navbar__desktop__actions--almagro {
      color: #4D4D4D; }
    .Navbar__desktop__actions--socovesa {
      color: #303446; }
    .Navbar__desktop__actions > * {
      margin-right: 30px !important; }
    .Navbar__desktop__actions__avatarLabel {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 1.29;
      letter-spacing: -0.02px;
      color: inherit;
      text-decoration: none; }
    .Navbar__desktop__actions__avatar {
      height: 30px;
      width: 30px;
      margin: 0 5px;
      color: inherit;
      border-radius: 50%; }
    .Navbar__desktop__actions__notificationButton {
      position: relative !important; }
      .Navbar__desktop__actions__notificationButton--hasNotifications--almagro:before {
        content: '';
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #E52713;
        top: -2px;
        z-index: 100;
        right: -5px; }
      .Navbar__desktop__actions__notificationButton--hasNotifications--socovesa:before {
        content: '';
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #53308f;
        top: -2px;
        z-index: 100;
        right: -5px; }
      .Navbar__desktop__actions__notificationButton--hasNotifications--pilares:before {
        content: '';
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #ff6900;
        top: -2px;
        z-index: 100;
        right: -5px; }
      .Navbar__desktop__actions__notificationButton--hasNotifications--empresas:before {
        content: '';
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #0093ca;
        top: -2px;
        z-index: 100;
        right: -5px; }
    .Navbar__desktop__actions__notifications {
      height: 30px;
      width: 30px;
      position: relative; }
      .Navbar__desktop__actions__notifications:before {
        content: '';
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        width: 5px;
        height: 5px;
        background-color: red; }
      .Navbar__desktop__actions__notifications--almagro {
        color: #4D4D4D !important; }
        .Navbar__desktop__actions__notifications--almagro:hover g {
          fill: #E52713 !important; }
      .Navbar__desktop__actions__notifications--socovesa {
        color: #303446 !important; }
        .Navbar__desktop__actions__notifications--socovesa:hover g {
          fill: #53308f !important; }
      .Navbar__desktop__actions__notifications--pilares {
        color: #6b6b6b !important; }
        .Navbar__desktop__actions__notifications--pilares:hover g {
          fill: #ff6900 !important; }
      .Navbar__desktop__actions__notifications--empresas {
        color: #6b6b6b !important; }
        .Navbar__desktop__actions__notifications--empresas:hover g {
          fill: #0093ca !important; }
    .Navbar__desktop__actions__logoutButton--almagro {
      color: #4D4D4D !important; }
      .Navbar__desktop__actions__logoutButton--almagro:hover g {
        fill: #E52713 !important; }
    .Navbar__desktop__actions__logoutButton--socovesa {
      color: #303446 !important; }
      .Navbar__desktop__actions__logoutButton--socovesa:hover g {
        fill: #53308f !important; }
    .Navbar__desktop__actions__logoutButton--pilares {
      color: #6b6b6b !important; }
      .Navbar__desktop__actions__logoutButton--pilares:hover g {
        fill: #ff6900 !important; }
    .Navbar__desktop__actions__logoutButton--empresas {
      color: #6b6b6b !important; }
      .Navbar__desktop__actions__logoutButton--empresas:hover g {
        fill: #0093ca !important; }

.Navbar__mobile {
  display: none;
  flex-basis: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 0 20px;
  position: fixed;
  padding: 0px 20px 20px 0px;
  border: solid 1px #d1d1d1;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  /* Position and sizing of burger button */
  /* Color/shape of burger icon bars */
  /* Color/shape of burger icon bars on hover*/
  /* Position and sizing of clickable cross button */
  /* Color/shape of close button cross */
  /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
  /* General sidebar styles */
  /* Morph shape necessary with bubble or elastic */
  /* Wrapper for item list */
  /* Individual item */
  /* Styling of overlay */ }
  .Navbar__mobile__icongroup {
    display: flex;
    align-items: flex-end;
    grid-gap: 16px;
    gap: 16px; }
  .Navbar__mobile--socovesa {
    background-color: #ffffff; }
    .Navbar__mobile--socovesa .bm-menu {
      background-color: #ffffff; }
  .Navbar__mobile--almagro {
    background-color: white; }
    .Navbar__mobile--almagro .bm-menu {
      background-color: white; }
  .Navbar__mobile--pilares {
    background-color: #EDEDEF; }
    .Navbar__mobile--pilares .bm-menu {
      background-color: #33383c; }
  .Navbar__mobile--empresas {
    background-color: #ffffff; }
    .Navbar__mobile--empresas .bm-menu {
      background-color: #ffffff; }
  .Navbar__mobile__logo {
    display: flex;
    align-self: center;
    margin: 20px 60px 0px; }
  .Navbar__mobile__notifications {
    margin: 25px 0 0;
    margin-left: auto; }
    .Navbar__mobile__notifications--almagro {
      color: black !important; }
      .Navbar__mobile__notifications--almagro:hover g {
        fill: #E52713 !important; }
    .Navbar__mobile__notifications--socovesa {
      color: #303446 !important; }
      .Navbar__mobile__notifications--socovesa:hover g {
        fill: #53308f !important; }
    .Navbar__mobile__notifications--pilares {
      color: #6b6b6b !important; }
      .Navbar__mobile__notifications--pilares:hover g {
        fill: #ff6900 !important; }
    .Navbar__mobile__notifications--empresas {
      color: #6b6b6b !important; }
      .Navbar__mobile__notifications--empresas:hover g {
        fill: #0093ca !important; }
  .Navbar__mobile__innerMenu__header {
    padding: 13px 18px; }
    .Navbar__mobile__innerMenu__header--almagro {
      color: black;
      border-bottom: 10px solid #3e3e3e; }
    .Navbar__mobile__innerMenu__header--socovesa {
      color: #303446;
      border-bottom: 10px solid #ededef; }
    .Navbar__mobile__innerMenu__header__logo {
      display: block;
      margin: 0 auto; }
    .Navbar__mobile__innerMenu__header__portraid {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin: 30px 0 30px; }
      .Navbar__mobile__innerMenu__header__portraid__avatar {
        height: 45px;
        width: 45px;
        border-radius: 50%; }
      .Navbar__mobile__innerMenu__header__portraid__name {
        color: inherit;
        margin: 0 10px 0; }
      .Navbar__mobile__innerMenu__header__portraid__drowDownButton {
        margin-left: auto;
        margin-right: 15px; }
  .Navbar__mobile__innerMenu__Menu {
    padding: 40px 15px 0px;
    font-size: 14px; }
  .Navbar__mobile .bm-burger-button {
    position: fixed;
    width: 20px;
    height: 20px;
    left: 20px;
    top: 25px; }
  .Navbar__mobile .bm-burger-bars {
    background: #373a47; }
  .Navbar__mobile .bm-burger-bars-hover {
    background: #a90000; }
  .Navbar__mobile .bm-cross-button {
    height: 24px;
    width: 24px;
    left: 8px;
    right: auto;
    position: absolute !important; }
  .Navbar__mobile .bm-cross {
    background: #bdc3c7; }
  .Navbar__mobile .bm-menu-wrap {
    position: fixed;
    height: 100%; }
  .Navbar__mobile .bm-menu {
    font-size: 1.15em; }
    .Navbar__mobile .bm-menu > * {
      outline: none; }
  .Navbar__mobile .bm-morph-shape {
    fill: #373a47; }
  .Navbar__mobile .bm-item-list {
    color: #b8b7ad; }
  .Navbar__mobile .bm-item {
    display: inline-block; }
  .Navbar__mobile .bm-overlay {
    background: rgba(0, 0, 0, 0.3); }

@media only screen and (max-width: 959px) {
  .Navbar__desktop {
    display: none; }
  .Navbar__mobile {
    display: flex; } }

.PropertyItem {
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 0 0 10px; }
  .PropertyItem--almagro {
    background-color: #F8F8F8;
    color: black; }
  .PropertyItem--pilares {
    background-color: #000000;
    color: #ffffff; }
  .PropertyItem--socovesa {
    background-color: #000000;
    color: #ffffff; }
  .PropertyItem__propertyNumber {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .PropertyItem__propertyNumber__label {
      font-size: 0.8rem; }
    .PropertyItem__propertyNumber__number {
      margin-left: 20px; }
    .PropertyItem__propertyNumber__button {
      margin-left: 20px; }

@media only screen and (max-width: 768px) {
  .PropertyItem {
    flex-direction: column; } }

.ProjectButton {
  display: flex;
  flex-direction: column;
  margin: 0; }
  .ProjectButton__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
  .ProjectButton__container {
    border-radius: 50%; }
    .ProjectButton__container--almagro {
      color: #4D4D4D; }
      .ProjectButton__container--almagro:hover {
        color: #F8F8F8;
        background-color: #ffffff; }
    .ProjectButton__container--socovesa {
      background-color: #ffffff;
      color: #53308f; }
      .ProjectButton__container--socovesa:hover {
        color: #ffffff;
        background-color: #53308f; }
    .ProjectButton__container--pilares:hover {
      color: #ff6900;
      background-color: #ffffff; }
    .ProjectButton__container--empresas {
      background-color: #ffffff;
      color: #003a63; }
  .ProjectButton__label {
    margin: 2px 0 0;
    text-align: center;
    font-size: 10px; }
    .ProjectButton__label--almagro {
      color: #a7a7a7; }

.ProjectItemMultiple {
  width: 100%;
  margin: 15px 0 0 !important; }
  .ProjectItemMultiple__summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .ProjectItemMultiple__button {
    position: initial;
    top: initial; }
  .ProjectItemMultiple .MuiExpansionPanelDetails-root {
    flex-direction: column; }
  .ProjectItemMultiple .MuiExpansionPanelSummary-expandIcon.Mui-expanded {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

@media only screen and (max-width: 768px) {
  .ProjectItemMultiple__summary {
    flex-direction: column; }
    .ProjectItemMultiple__summary * > {
      margin: 10px 0; } }

.PropertyList {
  height: 100%;
  width: 100%;
  overflow: scroll; }
  .PropertyList--almagro {
    background-color: white;
    color: #ffffff; }
  .PropertyList--socovesa {
    background-color: #202020;
    color: #ffffff; }
  .PropertyList--pilares {
    background-color: #202020;
    color: #ffffff; }
  .PropertyList__header {
    display: flex;
    flex-direction: row;
    padding: 20px 20px 0; }
    .PropertyList__header__container {
      display: flex;
      flex-direction: column;
      margin: 0 10px; }
      .PropertyList__header__container__projectNumber {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: -0.02px;
        color: #bebfc5; }
        .PropertyList__header__container__projectNumber__detail {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px;
          margin-right: 5px;
          border-radius: 20px;
          height: 20px;
          width: 20px; }
          .PropertyList__header__container__projectNumber__detail--almagro {
            color: #ffffff;
            background-color: #E52713; }
          .PropertyList__header__container__projectNumber__detail--socovesa {
            color: #ffffff;
            background-color: #53308f; }
          .PropertyList__header__container__projectNumber__detail--pilares {
            color: #ffffff;
            background-color: #ff6900; }
  .PropertyList__container {
    padding: 40px 30px; }

.ProjectItem {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  position: relative;
  flex: 0 0 100%;
  margin: 15px 0; }
  .ProjectItem:only-child {
    width: 100%; }
  .ProjectItem--almagro {
    background-color: #F8F8F8;
    color: #4D4D4D; }
  .ProjectItem--socovesa {
    background-color: #ffffff;
    color: #303446; }
  .ProjectItem--pilares {
    background-color: #ffffff;
    color: #303446; }
  .ProjectItem--gallery {
    display: flex;
    flex: initial;
    width: 100%;
    padding: 20px 50px;
    align-items: center;
    justify-content: space-between; }
    .ProjectItem--gallery__number {
      margin-right: 30%; }
  .ProjectItem__header {
    display: flex;
    padding: 20px;
    flex-direction: column; }
    .ProjectItem__header__imageLogo {
      display: flex;
      margin-right: 15px;
      padding-top: 5px;
      align-content: center; }
    .ProjectItem__header__grid {
      display: grid;
      grid-template-columns: 0.1fr 4fr 1fr; }
    .ProjectItem__header__name {
      margin: 0;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.13;
      letter-spacing: -0.02px;
      text-transform: uppercase; }
    .ProjectItem__header__apartments {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: -0.01px;
      margin: 5px 0 0; }
      .ProjectItem__header__apartments--almagro {
        color: #4D4D4D; }
      .ProjectItem__header__apartments--socovesa {
        color: #bebfc5; }
      .ProjectItem__header__apartments--pilares {
        color: #bebfc5; }
      .ProjectItem__header__apartments__number {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
        margin-right: 5px;
        border-radius: 20px;
        height: 20px;
        width: 20px; }
        .ProjectItem__header__apartments__number--almagro {
          color: #ffffff;
          background-color: #E52713; }
        .ProjectItem__header__apartments__number--socovesa {
          color: #ffffff;
          background-color: #53308f; }
        .ProjectItem__header__apartments__number--pilares {
          color: #ffffff;
          background-color: #ff6900; }
    .ProjectItem__header__button {
      position: absolute;
      right: 20px;
      cursor: pointer;
      top: 20px; }
  .ProjectItem__body__image {
    width: 100%;
    max-height: 300px; }
  .ProjectItem__footer {
    padding: 10px 50px; }
    .ProjectItem__footer__label {
      display: block;
      margin: 0px 0px 10px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.01px; }

.SwitchButton {
  display: flex; }
  .SwitchButton__icon {
    margin: 5px;
    cursor: pointer; }
    .SwitchButton__icon__no-select {
      opacity: 0.6; }

.ProjectList {
  width: 100%; }
  .ProjectList____switch {
    position: absolute; }
  .ProjectList__list {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    grid-gap: 1rem;
    gap: 1rem; }
  .ProjectList__others {
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
  .ProjectList__otherProjectsAccordion {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.03) !important;
    box-shadow: none !important;
    border-radius: 10px !important;
    margin: 20px 0px; }
  .ProjectList__subsidiaryNames--almagro {
    margin: 1rem 0 0 0.7rem;
    color: #ffffff; }
  .ProjectList__subsidiaryNames--socovesa {
    margin: 1rem 0 0 0.7rem;
    color: #303446; }
  .ProjectList__subsidiaryNames--pilares {
    margin: 1rem 0 0 0.7rem;
    color: #303446; }

.DocumentItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #b7aec8;
  margin: 0 0 10px; }
  .DocumentItem__nameDateItem {
    display: grid; }
    .DocumentItem__nameDateItem__date {
      font-size: 12px;
      color: #4D4D4D;
      margin-bottom: 6px;
      text-transform: none !important; }
    .DocumentItem__nameDateItem__docname {
      color: #4D4D4D;
      font-size: 14px;
      font-weight: bolder; }
    .DocumentItem__nameDateItem__lastModifiedDate {
      color: #4D4D4D;
      display: none; }
  .DocumentItem--socovesa {
    color: #303446;
    background-color: inherit; }
  .DocumentItem--almagro {
    color: #4D4D4D;
    background-color: inherit; }
  .DocumentItem--pilares {
    color: #303446;
    background-color: inherit; }
  .DocumentItem--empresas {
    color: #303446;
    border: 1px solid #b7aec8;
    background-color: inherit; }
  .DocumentItem__simbol {
    padding: 15px; }
    .DocumentItem__simbol--socovesa {
      color: inherit;
      background-color: inherit; }
    .DocumentItem__simbol--almagro {
      color: #4D4D4D;
      background-color: inherit; }
    .DocumentItem__simbol--pilares {
      color: inherit;
      background-color: inherit; }
    .DocumentItem__simbol--empresas {
      color: inherit;
      background-color: inherit; }
    .DocumentItem__simbol__icon {
      color: inherit; }
      .DocumentItem__simbol__icon--almagro {
        color: #E52713; }
  .DocumentItem__container {
    display: flex;
    align-items: center;
    padding: 0 20px 0;
    flex-basis: 100%; }
    .DocumentItem__container--socovesa {
      color: #303446; }
    .DocumentItem__container--almagro {
      color: #4D4D4D; }
    .DocumentItem__container--pilares {
      color: #303446; }
    .DocumentItem__container--empresas {
      color: #303446; }
    .DocumentItem__container__buttons {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      align-items: center; }
      .DocumentItem__container__buttons__lastModifiedDate {
        margin-right: 72px; }
    .DocumentItem__container__name {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
      letter-spacing: -0.02px;
      color: inherit;
      display: flex;
      text-decoration: none; }
      .DocumentItem__container__name--pointer {
        cursor: pointer; }
    .DocumentItem__container__date {
      display: flex;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.02px;
      color: #676977; }
    .DocumentItem__container__icon {
      display: flex;
      margin-left: auto;
      margin-right: 5px; }
      .DocumentItem__container__icon--socovesa {
        color: #53308f; }
      .DocumentItem__container__icon--almagro {
        color: #E52713; }
      .DocumentItem__container__icon--pilares {
        color: #ff6900; }
      .DocumentItem__container__icon--empresas {
        color: #6b6b6b; }

.DeleteButton {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .DeleteButton__container {
    display: flex;
    border: 1px solid #acacac;
    color: #acacac;
    border-radius: 50%; }
  .DeleteButton__label {
    display: flex;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.01px;
    color: #acacac; }

@media only screen and (max-width: 768px) {
  .DocumentItem__nameDateItem__date {
    display: none; }
  .DocumentItem__nameDateItem__lastModifiedDate {
    display: flex; }
  .DocumentItem__container__buttons__lastModifiedDate {
    display: none;
    margin: 0; } }

@charset "UTF-8";
.DocumentList {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  /* Estilos para Accordion */
  /* Estilos para AccordionSummary */
  /* Estilos para AccordionDetails */
  /* Estilos para el contenedor de los DocumentItem */
  /* Asegura que DocumentItem ocupe todo el ancho */ }
  .DocumentList__header {
    margin: 0 0 28px; }
  .DocumentList__uploadButton {
    width: 30% !important;
    margin-left: auto !important;
    margin-top: 15px !important; }
  .DocumentList__list {
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }
  .DocumentList__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 35px 0 0 auto; }
    .DocumentList__buttons__select {
      flex-basis: 40%;
      margin-right: 30px !important; }
  .DocumentList__dialogModal {
    padding: 20px !important; }
    .DocumentList__dialogModal__title {
      padding: 20px; }
    .DocumentList__dialogModal__content {
      padding: 20px; }
    .DocumentList__dialogModal__actions {
      padding: 10px; }
      .DocumentList__dialogModal__actions__button {
        width: 250px !important; }
  .DocumentList .DocumentList__group {
    transition: all 0.3s ease;
    /* Transición suave para el acordeón */ }
  .DocumentList .DocumentList__title {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f1f1f1;
    /* Fondo para el título */
    font-weight: bold; }
  .DocumentList .DocumentList__arrow {
    margin-left: 10px; }
  .DocumentList .DocumentList__documents {
    display: block;
    visibility: visible;
    padding: 15px;
    /* Añade padding para un mejor espaciado */
    overflow: auto;
    /* Evita que el contenido se desborde */
    width: 100%;
    /* Asegura que ocupe todo el ancho disponible */ }
  .DocumentList__details {
    display: flex;
    width: 98%;
    flex-direction: column;
    /* Coloca cada item en una nueva línea */
    margin-bottom: 10px;
    /* Espaciado entre cada item */ }
  .DocumentList .DocumentItem {
    width: 100%;
    /* Cada DocumentItem ocupa todo el ancho */
    margin-bottom: 15px;
    /* Espaciado entre DocumentItems */ }

.Milestones {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 0; }
  .Milestones__header {
    flex: 2 1; }
    .MuiExpansionPanelSummary-content.Mui-expanded .Milestones__header {
      margin-left: 32px; }
  .Milestones__container {
    display: flex;
    flex-direction: row;
    flex-basis: 100%; }
    .Milestones__panel__children.Mui-expanded .Milestones__container.MuiExpansionPanelDetails-root {
      padding: 0 32px; }
  .Milestones__icon {
    align-self: center;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 20px;
    z-index: 3; }
    .Milestones__panel__children.Mui-expanded .Milestones__icon {
      height: 90%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin-top: 5%;
      margin-bottom: 5%;
      border-radius: 10px; }
    .Milestones__icon--socovesa {
      background-color: #53308f; }
    .Milestones__icon--pilares {
      background-color: #ff6900; }
    .Milestones__icon--almagro {
      background-color: #E52713; }
    .Milestones__icon--empresas {
      background-color: #0093ca; }
  .Milestones__sub_title--almagro {
    color: #ffffff; }
  .Milestones__sub_title--socovesa {
    color: #676977; }
  .Milestones__sub_title.MuiTypography-h5 {
    font-size: 13px; }
  .Milestones__title {
    align-self: center; }
    .Milestones__title--almagro {
      color: #838591; }
    .Milestones__title.MuiTypography-h2 {
      font-size: 15px;
      font-weight: bold;
      line-height: 1.13;
      letter-spacing: -0.02px; }
      .proxima .Milestones__title.MuiTypography-h2 {
        color: #838591;
        font-weight: normal; }
    .Milestones__title--socovesa {
      color: #53308f; }
      .finalizada .Milestones__title--socovesa {
        color: #c6bbda;
        font-weight: normal; }
    .Milestones__title--almagro {
      color: #ee3424; }
      .finalizada .Milestones__title--almagro {
        color: #ba4035;
        font-weight: normal; }
    .Milestones__title--pilares {
      color: #ff6900; }
      .finalizada .Milestones__title--pilares {
        color: #ffc49e;
        font-weight: normal; }
    .Milestones__title--empresas {
      color: #0093ca; }
      .finalizada .Milestones__title--empresas {
        color: #c6bbda;
        font-weight: normal; }
  .Milestones__date--almagro {
    color: #4D4D4D; }
  .Milestones__date--socovesa {
    color: #546e7a; }
  .Milestones__date--pilares {
    color: #676977; }
  .Milestones__date--empresas {
    color: #546e7a; }
  .Milestones__detail {
    margin: 0;
    font-size: 14px; }
    .Milestones__detail--socovesa {
      color: #546e7a; }
    .Milestones__detail--almagro {
      color: #4D4D4D; }
    .Milestones__detail--pilares {
      color: #546e7a; }
    .Milestones__detail--empresas {
      color: #546e7a; }
  .Milestones__bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
    margin-left: auto; }
    .Milestones__bottom__detailButton {
      display: flex; }
  .Milestones__panel__children.MuiPaper-root {
    box-shadow: none; }
    .Milestones__panel__children.MuiPaper-root .MuiExpansionPanelSummary-root {
      padding-left: 0;
      position: initial; }
      .Milestones__panel__children.MuiPaper-root .MuiExpansionPanelSummary-root.Mui-expanded {
        min-height: auto; }
    .Milestones__panel__children.MuiPaper-root.Mui-expanded {
      margin: 0 0 16px 0; }
      .Milestones__panel__children.MuiPaper-root.Mui-expanded .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 12px 0;
        min-height: auto; }
    .Milestones__panel__children.MuiPaper-root:before {
      height: 0; }
  .Milestones--deactivate__title--socovesa {
    color: #c6bbda; }
  .Milestones--deactivate__title--pilares {
    color: #ffc49e; }
  .Milestones--deactivate__title--almagro {
    color: #4D4D4D; }
  .Milestones--deactivate__title--empresas {
    color: #c6bbda; }
  .Milestones--deactivate__date--almagro {
    color: #4D4D4D; }
  .Milestones--deactivate__date--socovesa {
    color: #546e7a; }
  .Milestones--deactivate__date--pilares {
    color: #676977; }
  .Milestones--deactivate__date--empresas {
    color: #bebfc5; }

.WorkProgress {
  display: flex;
  flex-direction: column;
  background-color: inherit;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  width: 100%;
  color: inherit; }
  .WorkProgress__empty {
    color: inherit;
    padding: 30px;
    border-radius: 4px; }
    .WorkProgress__empty--almagro {
      color: #4D4D4D; }
  .WorkProgress .MuiExpansionPanelSummary-root.Mui-focused {
    background-color: transparent; }
  .WorkProgress .MuiExpansionPanel-root.Mui-disabled {
    background-color: transparent; }
  .WorkProgress--socovesa {
    background-color: #ffffff; }
  .WorkProgress--pilares {
    background-color: #ffffff; }
  .WorkProgress--almagro {
    background-color: #FFFFFF; }
    .WorkProgress--almagro .WorkProgress__header .MuiTypography-root {
      color: #4D4D4D; }
  .WorkProgress--empresas {
    background-color: #ffffff; }
  .WorkProgress__panel__title.MuiTypography-root {
    font-size: 16px;
    font-weight: bold;
    color: #303446; }
    .inactivo .WorkProgress__panel__title.MuiTypography-root {
      color: #b3b3b4; }
  .WorkProgress__panel__sub_title.MuiTypography-root {
    font-size: 12px;
    margin-top: 10px;
    color: #303446; }
  .WorkProgress__icon {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 20px;
    background-image: url(/static/media/ico_obra.5c176ae8.svg);
    background-position: center;
    background-repeat: no-repeat; }
    .WorkProgress__icon--socovesa {
      background-color: #53308f; }
    .WorkProgress__icon--pilares {
      background-color: #ff6900; }
    .WorkProgress__icon--almagro {
      background-color: #E52713; }
    .WorkProgress__icon--empresas {
      background-color: #0093ca; }
    .inactivo .WorkProgress__icon {
      background-color: #b3b3b4; }
  .WorkProgress__header {
    height: 100%;
    align-self: center;
    flex: 10 1; }
  .WorkProgress .MuiPaper-root {
    background: transparent; }
  .WorkProgress__panel__parent.MuiPaper-root {
    box-shadow: none; }
    .WorkProgress__panel__parent.MuiPaper-root .MuiExpansionPanelDetails-root {
      padding: 0; }
    .WorkProgress__panel__parent.MuiPaper-root .MuiExpansionPanelSummary-content.Mui-expanded {
      margin: 12px 0; }
    .WorkProgress__panel__parent.MuiPaper-root .MuiExpansionPanelSummary-root.Mui-disabled {
      opacity: 1; }
    .WorkProgress__panel__parent.MuiPaper-root .MuiExpansionPanelSummary-root {
      min-height: 68px; }
    .WorkProgress__panel__parent.MuiPaper-root.Mui-expanded {
      border-bottom: 2px solid #d8d8d8;
      margin: 0; }
      .WorkProgress__panel__parent.MuiPaper-root.Mui-expanded:before {
        opacity: 1; }
  .WorkProgress__container {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    padding-left: 65px;
    position: relative;
    overflow: hidden; }
    .WorkProgress__container .linea {
      position: absolute;
      height: calc(100% - 70px);
      background: red;
      width: 2px;
      z-index: 1;
      right: calc(100% - 72px);
      margin: auto;
      top: 2px;
      bottom: 10px; }
      @media only screen and (max-width: 768px) {
        .WorkProgress__container .linea .linea {
          height: calc(100% - 90px); } }
      .WorkProgress__container .linea--socovesa {
        background-color: #eff3f8; }
      .WorkProgress__container .linea--pilares {
        background-color: #ededef; }
      .WorkProgress__container .linea--almagro {
        background-color: #4D4D4D; }
      .WorkProgress__container .linea--empresas {
        background-color: #bebfc5; }
    .WorkProgress__container__body {
      display: flex;
      flex-direction: column;
      flex-basis: 100%; }
    .WorkProgress__container div:last-child hr {
      height: 1px;
      background: transparent;
      border: 0;
      border-bottom: solid 1px #ededef;
      width: 88%;
      margin: 0;
      padding: 0;
      float: right;
      margin-right: 5%; }
    .WorkProgress__container div:last-child > hr {
      border-bottom: 0; }
    .WorkProgress__container:not(:last-child) .WorkProgressSimbol:after {
      z-index: -1;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      -webkit-transform: translate(-50%);
              transform: translate(-50%); }
    .WorkProgress__container:not(:last-child) .WorkProgressSimbol--socovesa:after {
      border-left: 2px solid #bebfc5; }
    .WorkProgress__container:not(:last-child) .WorkProgressSimbol--almagro:after {
      border-left: 2px solid #a7a7a7; }
    .WorkProgress__container:not(:last-child) .WorkProgressSimbol--pilares:after {
      border-left: 2px solid #bebfc5; }
    .WorkProgress__container:not(:last-child) .WorkProgressSimbol--empresas:after {
      border-left: 2px solid #bebfc5; }

.CalendarItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px 25px;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  margin: 0 0 10px;
  justify-content: space-between; }
  .CalendarItem--almagro {
    color: #4D4D4D;
    background-color: #FFFFFF; }
  .CalendarItem--socovesa {
    background-color: #4c5256; }
  .CalendarItem__content {
    display: flex;
    flex-direction: column; }
    .CalendarItem__content__date {
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: -0.02px;
      color: inherit;
      margin: 0; }
    .CalendarItem__content__title {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      text-transform: uppercase;
      line-height: 1.38;
      letter-spacing: -0.01px;
      margin-top: 6px; }
    .CalendarItem__content__colapse {
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -0.01px;
      margin-top: 9px; }
  .CalendarItem__button {
    color: inherit !important; }

.CalendarCard {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }
  .CalendarCard__calendar {
    width: 100%; }
    .CalendarCard__calendar .rdrMonthsVertical {
      flex-direction: column;
      align-items: center; }
    .CalendarCard__calendar--pilares .rdrDayToday .rdrDayNumber span:after {
      background-color: #ff6900; }
    .CalendarCard__calendar--socovesa .rdrDayToday .rdrDayNumber span:after {
      background-color: #53308f; }
    .CalendarCard__calendar--almagro {
      color: #4D4D4D; }
      .CalendarCard__calendar--almagro .rdrDayToday .rdrDayNumber span:after {
        background-color: #E52713; }
      .CalendarCard__calendar--almagro .rdrMonthAndYearWrapper {
        color: #4D4D4D;
        background-color: #FFFFFF; }
        .CalendarCard__calendar--almagro .rdrMonthAndYearWrapper .rdrPprevButton {
          background-color: #FFFFFF; }
          .CalendarCard__calendar--almagro .rdrMonthAndYearWrapper .rdrPprevButton i {
            border-color: transparent #4D4D4D transparent transparent; }
        .CalendarCard__calendar--almagro .rdrMonthAndYearWrapper .rdrNextButton {
          background-color: #FFFFFF; }
          .CalendarCard__calendar--almagro .rdrMonthAndYearWrapper .rdrNextButton i {
            border-color: transparent transparent transparent #4D4D4D; }
      .CalendarCard__calendar--almagro .rdrMonths {
        color: #4D4D4D;
        background-color: #FFFFFF; }
        .CalendarCard__calendar--almagro .rdrMonths .rdrDays .rdrDay .rdrDayNumber span {
          color: #4D4D4D !important; }
  .CalendarCard__calendarFooter {
    display: flex;
    color: #E52713;
    background-color: #FFFFFF; }
  .CalendarCard__calendarButton {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: -0.02px; }
    .CalendarCard__calendarButton--almagro {
      color: #4D4D4D; }
    .CalendarCard__calendarButton--socovesa {
      color: #000000; }
    .CalendarCard__calendarButton--pilares {
      color: #000000; }
    .CalendarCard__calendarButton__icon {
      padding: 0;
      height: auto;
      width: auto;
      border-radius: 50%;
      margin: 0 5px; }
      .CalendarCard__calendarButton__icon--almagro {
        color: #4D4D4D;
        background-color: #E52713 !important; }
      .CalendarCard__calendarButton__icon--pilares {
        color: #ffffff;
        background-color: #ff6900 !important; }
      .CalendarCard__calendarButton__icon--socovesa {
        color: #ffffff;
        background-color: #53308f !important; }
  .CalendarCard__alertIcon {
    display: flex;
    padding: 25px 10px 0px 25px; }
    .CalendarCard__alertIcon--almagro {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
      background-color: #E52713 !important; }
    .CalendarCard__alertIcon--pilares {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
      background-color: #ff6900 !important; }
    .CalendarCard__alertIcon--socovesa {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
      background-color: #53308f !important; }
  .CalendarCard--almagro--active {
    background-color: #FFFFFF;
    color: #4D4D4D; }
  .CalendarCard--socovesa--active {
    background-color: #33383c;
    color: #ffffff; }
  .CalendarCard--pilares--active {
    background-color: #33383c;
    color: #ffffff; }
  .CalendarCard__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    padding: 38px 35px 15px; }
    .CalendarCard__header--almagro {
      color: #ffffff; }
    .CalendarCard__header--active {
      padding: 25px;
      justify-content: space-between;
      border-bottom: 1px solid #bebfc5; }
    .CalendarCard__header__arrow {
      display: flex;
      align-items: center;
      color: inherit !important;
      text-transform: inherit !important;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: -0.02px;
      text-align: right; }
    .CalendarCard__header__label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-transform: uppercase;
      line-height: 1.29;
      letter-spacing: -0.02px;
      text-align: center;
      color: #bebfc5;
      margin-right: 10px; }
    .CalendarCard__header__date {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.02px;
      color: inherit; }
  .CalendarCard__detailButton {
    display: flex !important;
    align-items: center !important;
    margin-left: auto !important;
    padding: 20px !important;
    color: inherit !important; }
    .CalendarCard__detailButton__icon {
      margin-left: 10px; }
  .CalendarCard__body--active {
    padding: 16px 30px; }
  .CalendarCard__body__list {
    margin: 15px 0 0; }
  .CalendarCard__body__backArrow {
    color: inherit !important; }
  .CalendarCard__body__errorMessage {
    align-items: center;
    text-align: center;
    flex-direction: row;
    padding: 15px 25px;
    border-radius: 3px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    margin: 20px 0 10px;
    background-color: #4c5256; }

.NotificationList {
  padding: 10px 18px;
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(103, 105, 119, 0.4);
  margin: 0 0 5px; }
  .NotificationList--socovesa {
    background-color: #ffffff;
    border-left: 10px solid #53308f; }
  .NotificationList--almagro {
    color: #4D4D4D;
    background-color: #F8F8F8;
    border-left: 10px solid #E52713; }
  .NotificationList--pilares {
    background-color: #ffffff;
    border-left: 10px solid #ff6900; }
  .NotificationList--empresas {
    background-color: #ffffff;
    border-left: 10px solid #0093ca; }
  .NotificationList__buttonWrapper {
    width: 100% !important; }
  .NotificationList__container {
    display: flex;
    align-items: center !important;
    width: 100%; }
    .NotificationList__container__url {
      text-transform: none !important;
      text-decoration: underline !important; }
    .NotificationList__container__iconWrapper {
      display: flex;
      align-items: center;
      border-radius: 50%;
      padding: 5px;
      margin-right: 10px; }
      .NotificationList__container__iconWrapper--almagro {
        background-color: #E52713; }
      .NotificationList__container__iconWrapper--socovesa {
        background-color: #303446; }
      .NotificationList__container__iconWrapper--pilares {
        background-color: #303446; }
      .NotificationList__container__iconWrapper--empresas {
        background-color: #303446; }
    .NotificationList__container__notification {
      color: inherit;
      line-height: 1.29;
      letter-spacing: -0.02px;
      text-transform: none;
      font-size: 14px; }
      .NotificationList__container__notification--almagro {
        color: #4D4D4D; }
      .NotificationList__container__notification--socovesa {
        color: inherit; }
      .NotificationList__container__notification--pilares {
        color: inherit; }
      .NotificationList__container__notification--empresas {
        color: inherit; }
    .NotificationList__container__url {
      margin-left: auto !important; }

.Home__body {
  background-color: inherit; }
  .Home__body--pilares {
    background-color: #EDEDEF; }
  .Home__body--socovesa {
    background-color: inherit; }
  .Home__body--almagro {
    background-color: inherit; }
  .Home__body__greeting {
    font-size: 18px;
    letter-spacing: -0.02px; }
    .Home__body__greeting--socovesa {
      color: #acacac; }
    .Home__body__greeting--almagro {
      color: #4D4D4D; }
    .Home__body__greeting--pilares {
      color: #acacac; }
  .Home__body__username {
    margin: 0 0 20px !important; }
    .Home__body__username--socovesa {
      color: #53308f; }
    .Home__body__username--almagro {
      color: #4D4D4D; }
    .Home__body__username--pilares {
      color: #ff6900; }
  .Home__body__section {
    display: flex; }
    .Home__body__section__container {
      display: flex;
      flex-direction: column;
      margin: 50px 0 36px;
      overflow: initial; }
      .Home__body__section__container:only-child {
        flex-basis: 100%; }
      .Home__body__section__container:first-child:nth-last-child(2),
      .Home__body__section__container:first-child:nth-last-child(2) ~ * {
        flex-direction: column;
        flex-basis: 50%;
        margin: 0 30px 0px 0px; }
        .Home__body__section__container:first-child:nth-last-child(2):last-child,
        .Home__body__section__container:first-child:nth-last-child(2) ~ *:last-child {
          margin: 0; }
      .Home__body__section__container__header {
        display: flex;
        flex-direction: row;
        align-items: center; }
        .Home__body__section__container__header > * {
          margin-left: 10px; }
        .Home__body__section__container__header__name {
          font-size: 18px;
          font-weight: bold;
          letter-spacing: -0.02px; }
          .Home__body__section__container__header__name--socovesa {
            color: #303446; }
          .Home__body__section__container__header__name--almagro {
            color: #4D4D4D; }
        .Home__body__section__container__header__detail {
          font-size: 12px;
          letter-spacing: -0.01px;
          color: #9f9f9f; }
        .Home__body__section__container__header__button {
          margin-left: auto !important; }
      .Home__body__section__container__list {
        display: flex;
        flex-direction: column; }
        .Home__body__section__container__list__item {
          border-radius: 4px;
          box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }
          .Home__body__section__container__list__item:nth-child(-n + 3) {
            display: flex;
            flex-direction: row;
            flex-basis: 33.3333%;
            margin: 0 30px 0 0; }
            .Home__body__section__container__list__item:nth-child(-n + 3):last-child {
              margin: 0; }
          .Home__body__section__container__list__item:only-child {
            display: flex;
            flex-direction: row;
            flex-basis: 100%;
            margin: 0; }
          .Home__body__section__container__list__item--socovesa {
            background-color: #ffffff; }
          .Home__body__section__container__list__item--almagro {
            background-color: #F8F8F8; }
          .Home__body__section__container__list__item--pilares {
            background-color: #ffffff; }
          .Home__body__section__container__list__item--pilares {
            background-color: #ffffff; }
  .Home__body__rectangle {
    margin: 30px 0 40px;
    width: 100%;
    height: 54px;
    border-radius: 4px; }
    .Home__body__rectangle--socovesa {
      background-color: #53308f; }
    .Home__body__rectangle--pilares {
      background-color: #ff6900; }
    .Home__body__rectangle--almagro {
      background-color: #F8F8F8; }

@media only screen and (max-width: 768px) {
  .Home__Menu {
    display: none; }
  .Home__body {
    padding: 90px 10px;
    max-width: 95vw; }
    .Home__body__greeting {
      font-size: 14px; }
    .Home__body__username {
      font-size: 28px; }
    .Home__body__messages {
      padding: 10px; }
      .Home__body__messages__notification {
        font-size: 14px; }
      .Home__body__messages__url {
        padding: 0 !important; }
    .Home__body__section {
      margin: 2px;
      flex-direction: column; }
      .Home__body__section__container {
        margin: 0 0 36px;
        overflow: hidden !important; }
        .Home__body__section__container__header__button {
          display: none !important; }
        .Home__body__section__container:first-child:nth-last-child(2),
        .Home__body__section__container:first-child:nth-last-child(2) ~ * {
          flex-direction: column;
          flex-basis: 100%;
          margin: 0; }
          .Home__body__section__container:first-child:nth-last-child(2):last-child,
          .Home__body__section__container:first-child:nth-last-child(2) ~ *:last-child {
            margin: 0; }
        .Home__body__section__container__list {
          flex-direction: column; }
          .Home__body__section__container__list__item {
            width: 100%; }
            .Home__body__section__container__list__item:nth-child(-n + 3) {
              flex-direction: column;
              flex-basis: auto; } }

.MessageForm {
  display: flex; }
  .MessageForm__form {
    display: flex;
    flex-direction: column;
    flex-basis: 100%; }
    .MessageForm__form__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 20px 0; }

@media only screen and (max-width: 768px) {
  .MessageForm__form {
    padding: 0 5vh; }
    .MessageForm__form__buttons {
      flex-direction: column; }
      .MessageForm__form__buttons > * {
        margin: 0 0 20px !important; } }

.MessageItem {
  display: flex;
  padding: 30px 20px 30px 0px;
  background-color: inherit;
  flex-direction: column;
  padding: 40px 40px; }
  .MessageItem--almagro {
    border-bottom: 1px solid #3c3c3c; }
    .MessageItem--almagro--read {
      background-color: #FFFFFF; }
  .MessageItem--pilares {
    border-bottom: 1px solid #d8d8d8; }
    .MessageItem--pilares--read {
      background-color: #f4f4f5; }
  .MessageItem--socovesa {
    border-bottom: 1px solid #d8d8d8; }
    .MessageItem--socovesa--read {
      background-color: #f4f4f5; }
  .MessageItem--empresas {
    border-bottom: 1px solid #d8d8d8; }
    .MessageItem--empresas--read {
      background-color: #f4f4f5; }
  .MessageItem__resume {
    display: flex;
    justify-content: space-between;
    flex-direction: row; }
    .MessageItem__resume__button {
      color: inherit !important; }
    .MessageItem__resume__title {
      display: flex;
      align-items: center;
      font-size: 1rem;
      text-transform: none;
      font-weight: initial;
      text-align: left;
      margin-left: 10px; }
    .MessageItem__resume__date {
      display: flex;
      align-items: center;
      font-size: 1rem;
      text-transform: none;
      font-weight: initial; }
    .MessageItem__resume__from {
      display: flex;
      align-items: center;
      font-weight: bold;
      padding: 0 0px 0 30px;
      position: relative;
      font-size: 1rem;
      text-transform: none;
      text-align: left; }
      .MessageItem__resume__from--almagro--notRead:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        width: 15px;
        height: 15px;
        border-radius: 7.5px;
        background-color: #E52713;
        margin-right: 10px; }
      .MessageItem__resume__from--socovesa--notRead:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        width: 15px;
        height: 15px;
        border-radius: 7.5px;
        background-color: #53308f;
        margin-right: 10px; }
      .MessageItem__resume__from--pilares--notRead:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        width: 15px;
        height: 15px;
        border-radius: 7.5px;
        background-color: #ff6900;
        margin-right: 10px; }
      .MessageItem__resume__from--empresas--notRead:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        width: 15px;
        height: 15px;
        border-radius: 7.5px;
        background-color: #0093ca;
        margin-right: 10px; }
  .MessageItem__detail {
    display: flex;
    flex-direction: column;
    padding: 30px 40px 30px;
    margin: 30px 0 0;
    transition: width 600ms ease-out, height 600ms ease-out; }
    .MessageItem__detail--almagro {
      border-top: 1px solid #3c3c3c; }
    .MessageItem__detail--pilares {
      border-top: 1px solid #d8d8d8; }
    .MessageItem__detail--socovesa {
      border-top: 1px solid #d8d8d8; }
    .MessageItem__detail--empresas {
      border-top: 1px solid #d8d8d8; }
    .MessageItem__detail__title {
      font-size: 16px;
      display: flex;
      align-items: center;
      margin: 0;
      text-transform: uppercase; }
    .MessageItem__detail__name {
      font-size: 16px;
      letter-spacing: -0.02px;
      margin: 0 0 4px; }
    .MessageItem__detail__subtitle {
      letter-spacing: -0.02px;
      margin: 0 0 29px; }
    .MessageItem__detail__text {
      font-size: 16px;
      letter-spacing: -0.02px;
      color: #303446; }
    .MessageItem__detail__body {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 10px 0 40px;
      position: relative; }
      .MessageItem__detail__body__message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 85px; }
      .MessageItem__detail__body--almagro:not(:last-of-type) {
        border-bottom: 1px solid #3c3c3c; }
      .MessageItem__detail__body--almagro--notRead:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: -25px;
        top: 35px;
        width: 15px;
        height: 15px;
        border-radius: 7.5px;
        background-color: #E52713;
        margin-right: 10px; }
      .MessageItem__detail__body--pilares:not(:last-of-type) {
        border-bottom: 1px solid #d8d8d8; }
      .MessageItem__detail__body--pilares--notRead:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: -25px;
        top: 35px;
        width: 15px;
        height: 15px;
        border-radius: 7.5px;
        background-color: #ff6900;
        margin-right: 10px; }
      .MessageItem__detail__body--socovesa:not(:last-of-type) {
        border-bottom: 1px solid #d8d8d8; }
      .MessageItem__detail__body--socovesa--notRead:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: -25px;
        top: 35px;
        width: 15px;
        height: 15px;
        border-radius: 7.5px;
        background-color: #53308f;
        margin-right: 10px; }
      .MessageItem__detail__body--empresas:not(:last-of-type) {
        border-bottom: 1px solid #d8d8d8; }
      .MessageItem__detail__body--empresas--notRead:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: -25px;
        top: 35px;
        width: 15px;
        height: 15px;
        border-radius: 7.5px;
        background-color: #0093ca;
        margin-right: 10px; }
      .MessageItem__detail__body__from {
        letter-spacing: -0.02px;
        color: #303446;
        font-weight: bold;
        margin: 0 0 4px; }
    .MessageItem__detail__button {
      width: 180px; }
  .MessageItem__response {
    padding: 0px 40px; }

@media only screen and (max-width: 768px) {
  .MessageItem {
    padding: 0; }
    .MessageItem__detail {
      padding: 4vh 5vh; }
      .MessageItem__detail__body {
        padding: 0; }
        .MessageItem__detail__body__message {
          margin-right: 0; }
    .MessageItem__resume {
      padding: 4vh 5vh; }
      .MessageItem__resume__from {
        padding: 0; }
        .MessageItem__resume__from--empresas--notRead:before {
          left: -3.5vh; }
        .MessageItem__resume__from--almagro--notRead:before {
          left: -3.5vh; }
        .MessageItem__resume__from--pilares--notRead:before {
          left: -3.5vh; }
        .MessageItem__resume__from--socovesa--notRead:before {
          left: -3.5vh; }
      .MessageItem__resume__title {
        margin: 0; }
      .MessageItem__resume__collapseButton {
        display: flex;
        align-items: center; } }

.DynamicLabel {
  font-size: 0.8rem !important;
  padding: 0 3rem 0 0 !important;
  top: -1.5vh; }

.DynamicLabel.MuiInputLabel-shrink {
  font-size: 0.8rem !important;
  white-space: nowrap !important; }

.Searchbar__input {
  width: 100%;
  border-radius: 5px;
  border: solid 1px #bebfc5;
  background-color: #ffffff; }

.Searchbar__button {
  display: flex !important;
  align-items: center !important;
  margin-left: auto !important; }

@media screen and (max-width: 768px) {
  .Searchbar__input {
    max-width: 95vw; } }

.NewMessageForm__form__buttons {
  display: flex !important;
  justify-content: space-between !important;
  margin: 20px 0 0; }

.NewMessageForm__form__fileUpload {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .NewMessageForm__form__fileUpload__file {
    display: flex; }
    .NewMessageForm__form__fileUpload__file__link {
      text-decoration: none; }
      .NewMessageForm__form__fileUpload__file__link :visited {
        color: inherit; }
    .NewMessageForm__form__fileUpload__file__icon {
      margin-left: 10px;
      cursor: pointer; }

.toChip {
  padding: 7px 15px;
  border-radius: 18px;
  background-color: #ededef; }

@media only screen and (max-width: 768px) {
  .NewMessageForm__form__buttons {
    flex-direction: column-reverse; }
    .NewMessageForm__form__buttons > * {
      margin: 0 0 20px !important; } }

.MessageList {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  position: relative; }
  .MessageList--almagro {
    background-color: #F8F8F8;
    color: #4D4D4D; }
  .MessageList--socovesa {
    background-color: #ffffff; }
  .MessageList--pilares {
    background-color: #ffffff; }
  .MessageList--empresas {
    background-color: #ffffff; }
  .MessageList__collapseButton {
    color: inherit !important;
    position: absolute !important;
    right: 20px;
    border-radius: 50% !important;
    border: 2px solid currentColor !important;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    .MessageList__collapseButton__active {
      -webkit-transform: none;
              transform: none; }
  .MessageList__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .MessageList__header--collapse {
      flex-direction: column;
      align-items: start; }
    .MessageList__header__title {
      margin: 0;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.03px;
      text-transform: uppercase; }
      .MessageList__header__title__notRead {
        left: 20px;
        font-weight: bold;
        position: relative; }
        .MessageList__header__title__notRead::before {
          content: '';
          display: inline-block;
          position: absolute;
          left: -20px;
          top: 6px;
          width: 15px;
          height: 15px;
          border-radius: 7.5px;
          background-color: #0093ca;
          margin-right: 10px; }
    .MessageList__header__notRead {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.02px;
      margin-right: 100px; }
      .MessageList__header__notRead__number {
        border-radius: 50%;
        padding: 3px 6px; }
        .MessageList__header__notRead__number--empresas {
          color: #ffffff;
          background-color: #0093ca; }
    .MessageList__header__colapse.MuiCollapse-entered {
      width: 100%; }
    .MessageList__header__colapse__writeMessageButton {
      padding: 15px 35px !important;
      margin: 0px 0 0px 20px !important; }
    .MessageList__header__colapse__tabContainer {
      color: inherit;
      display: flex;
      flex-direction: row;
      margin: 40px 0 0;
      width: 100%; }
      .MessageList__header__colapse__tabContainer--almagro {
        color: #4D4D4D; }
    .MessageList__header__colapse__mobile {
      display: none; }
  .MessageList__body__list {
    padding: 0;
    box-shadow: 0 2px 8px 0 rgba(103, 105, 119, 0.4); }

.MessagePagination {
  color: inherit;
  display: flex;
  margin-left: auto;
  align-items: center; }
  .MessagePagination__label {
    font-size: 12px;
    color: inherit;
    margin-right: 20px; }
  .MessagePagination__icon {
    color: inherit;
    margin-right: 20px; }

@media only screen and (max-width: 768px) {
  .MessageList__header {
    flex-direction: column;
    align-items: flex-start; }
    .MessageList__header__colapse {
      display: none; }
      .MessageList__header__colapse__mobile {
        display: flex;
        flex-direction: column;
        padding: 30px 0 0; }
  .MessageList__body__tabPanel {
    padding: 0 !important; }
  .MessageList__body__list {
    padding: 0; } }

.ClientMessages--almargro {
  color: #ffffff; }

.ClientMessages--socovesa {
  color: #000000; }

.ClientMessages--pilares {
  color: #000000; }

.ClientMessages__title {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.03px; }

.ClientMessages__searchBar {
  margin: 0 0 50px; }

.ClientMessages__item {
  margin: 10px 0; }

@media only screen and (max-width: 768px) {
  .ClientMessages {
    padding: 80px 10px 0;
    max-width: 95vw; } }

.shadow-readed {
  opacity: 0.5; }

.LastMessages {
  height: auto;
  border-radius: 4px;
  box-shadow: 2px 4px 0 rgba(103, 105, 119, 0.4); }
  .LastMessages__item__readed {
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid rgba(103, 105, 119, 0.4); }
    .LastMessages__item__readed--socovesa {
      background-color: #f4f4f5; }
    .LastMessages__item__readed--pilares {
      background-color: #f4f4f5; }
    .LastMessages__item__readed--almagro {
      background-color: #FFFFFF; }
  .LastMessages__item {
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid rgba(103, 105, 119, 0.4); }
    .LastMessages__item--almagro {
      background-color: #FFFFFF; }
  .LastMessages__circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin: 3px 10px 0px 0px;
    background-color: #53308f; }
    .LastMessages__circle--sovocesa {
      background-color: #53308f; }
    .LastMessages__circle--almagro {
      background-color: #ee3424; }
    .LastMessages__circle--pilares {
      background-color: #ff6900; }
  .LastMessages __name {
    text-transform: lowercase;
    text-transform: capitalize;
    font-family: "HelveticaNeue";
    font-weight: bold;
    font-size: 16px; }
    .LastMessages __name--sovocesa {
      color: #303446; }
    .LastMessages __name--pilares {
      color: #303446; }
    .LastMessages __name--almagro {
      color: #4D4D4D; }
  .LastMessages__asunto {
    text-transform: lowercase;
    font-family: "HelveticaNeue";
    font-size: 15px;
    display: flex;
    align-self: center;
    flex-direction: column;
    margin-left: 9px; }
    .LastMessages__asunto--socovesa {
      color: #303446; }
    .LastMessages__asunto--pilares {
      color: #303446; }
    .LastMessages__asunto--almagro {
      color: #4D4D4D; }
  .LastMessages__fecha {
    color: #acacac;
    display: grid;
    padding-left: 2%; }
    .LastMessages__fecha__container {
      padding-top: 0 !important;
      padding-right: 0 !important;
      margin-top: -5px !important;
      font-size: 13px !important;
      text-transform: uppercase !important; }

.ClientExecutive__container--socovesa {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }

.ClientExecutive__container--almagro {
  background-color: #F8F8F8;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }

.ClientExecutive__container--pilares {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }

.ClientExecutive__head {
  display: flex; }
  .ClientExecutive__head__avatarLabel {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: -0.02px;
    color: inherit; }
  .ClientExecutive__head__avatar {
    height: 100px;
    width: 100px;
    margin: 0 5px;
    color: inherit;
    border-radius: 50%; }
  .ClientExecutive__head__name_container {
    display: grid;
    margin-left: 20px; }
    .ClientExecutive__head__name_container__name--socovesa {
      color: #53308f;
      font-size: 32px;
      letter-spacing: -0.03px;
      margin-top: 20px;
      text-transform: capitalize; }
    .ClientExecutive__head__name_container__name--pilares {
      color: #ff6900;
      font-size: 32px;
      letter-spacing: -0.03px;
      margin-top: 20px;
      text-transform: capitalize; }
    .ClientExecutive__head__name_container__name--almagro {
      color: #4D4D4D;
      font-size: 32px;
      letter-spacing: -0.03px;
      margin-top: 20px;
      text-transform: capitalize; }
    .ClientExecutive__head__name_container__subname--socovesa {
      font-family: "Montserrat";
      color: #4a4d68;
      line-height: 1.13;
      letter-spacing: -0.01px;
      font-size: 16px;
      margin-top: -10px; }
    .ClientExecutive__head__name_container__subname--pilares {
      font-family: "Montserrat";
      color: #4a4d68;
      line-height: 1.13;
      letter-spacing: -0.01px;
      font-size: 16px;
      margin-top: -10px; }
    .ClientExecutive__head__name_container__subname--almagro {
      font-family: "Montserrat";
      color: #4D4D4D;
      line-height: 1.13;
      letter-spacing: -0.01px;
      font-size: 16px;
      margin-top: -10px; }

.ClientExecutive__descripcion--socovesa {
  width: 340px;
  height: 140px;
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: #4a4d68; }

.ClientExecutive__descripcion--almagro {
  width: 340px;
  height: 140px;
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: #4D4D4D; }

.ClientExecutive__descripcion--pilares {
  width: 340px;
  height: 140px;
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: #4a4d68; }

.ClientExecutive__data_container {
  display: grid; }

.ClientExecutive__data {
  font-family: "HelveticaNeue";
  font-size: 10px;
  line-height: 1.8;
  letter-spacing: -0.01px;
  color: #bebfc5;
  margin-bottom: 7px; }

.ClientExecutive__data__detail--socovesa {
  line-height: 1.13;
  letter-spacing: -0.02px;
  color: #4a4d68;
  font-family: "HelveticaNeue";
  font-size: 15px;
  margin-bottom: 7px; }

.ClientExecutive__data__detail--pilares {
  line-height: 1.13;
  letter-spacing: -0.02px;
  color: #4a4d68;
  font-family: "HelveticaNeue";
  font-size: 15px;
  margin-bottom: 7px; }

.ClientExecutive__data__detail--almagro {
  line-height: 1.13;
  letter-spacing: -0.02px;
  color: #4D4D4D;
  font-family: "HelveticaNeue";
  font-size: 15px;
  margin-bottom: 7px; }

.ClientExecutive__Line {
  height: 1px;
  background-color: #bebfc5;
  margin-left: 30px;
  margin-right: 30px; }

.ClientExecutive__contact {
  display: grid; }
  .ClientExecutive__contact__title {
    font-family: "HelveticaNeue";
    letter-spacing: -0.02px;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold; }
    .ClientExecutive__contact__title--socovesa {
      color: #303446; }
    .ClientExecutive__contact__title--pilares {
      color: #303446; }
    .ClientExecutive__contact__title--almagro {
      color: #4D4D4D; }
  .ClientExecutive__contact__text {
    font-family: "HelveticaNeue";
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.48px; }
    .ClientExecutive__contact__text--socovesa {
      color: #4a4d68; }
    .ClientExecutive__contact__text--pilares {
      color: #4a4d68; }
    .ClientExecutive__contact__text--almagro {
      color: #4D4D4D; }
  .ClientExecutive__contact__button {
    color: #ffffff !important;
    width: 40% !important;
    padding: 10px !important;
    font-weight: bold !important;
    margin-top: 40px !important; }
    .ClientExecutive__contact__button--socovesa {
      background-color: #53308f !important; }
    .ClientExecutive__contact__button--almagro {
      background-color: #ee3424 !important; }
    .ClientExecutive__contact__button--pilares {
      background-color: #ff6900 !important; }

@media only screen and (max-width: 768px) {
  .ClientExecutive__head {
    padding: 106px 0 0;
    flex-direction: column;
    align-items: center; }
    .ClientExecutive__head__name_container {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .ClientExecutive__head__name_container__name {
        font-size: 1.5rem; }
        .ClientExecutive__head__name_container__name--almagro {
          color: #ff6900; }
        .ClientExecutive__head__name_container__name--socovesa {
          color: #53308f; }
        .ClientExecutive__head__name_container__name--pilares {
          color: #ff6900; }
      .ClientExecutive__head__name_container__subname {
        margin-top: 2px; }
  .ClientExecutive__contact {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .ClientExecutive__contact__title {
      text-align: left;
      align-self: flex-start; } }

.white--text {
  color: #fff; }

.additional-card-almagro {
  background-color: #FFFFFF !important;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  margin-bottom: 20px;
  color: #4D4D4D !important; }

.ClientAdditional__container--socovesa {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }

.ClientAdditional__container--almagro {
  background-color: #F8F8F8 !important;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }

.ClientAdditional__container--pilares {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }

.ClientAdditional__container--empresas {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }

.ClientAdditional__head {
  display: flex;
  justify-content: space-between; }
  .ClientAdditional__head ._button_action {
    max-width: 300px; }
  .ClientAdditional__head__name_container {
    display: grid;
    margin-left: 20px; }
    .ClientAdditional__head__name_container__name--socovesa {
      color: #53308f;
      font-size: 32px;
      letter-spacing: -0.03px;
      margin-top: 20px;
      text-transform: capitalize;
      margin-bottom: 10px; }
    .ClientAdditional__head__name_container__name--pilares {
      color: #ff6900;
      font-size: 32px;
      letter-spacing: -0.03px;
      margin-top: 20px;
      text-transform: capitalize;
      margin-bottom: 10px; }
    .ClientAdditional__head__name_container__name--almagro {
      color: #4D4D4D !important;
      font-size: 32px;
      letter-spacing: -0.03px;
      margin-top: 20px;
      text-transform: capitalize;
      margin-bottom: 10px; }
    .ClientAdditional__head__name_container__subname--socovesa {
      font-family: 'Montserrat';
      color: #4a4d68;
      line-height: 1.13;
      letter-spacing: -0.01px;
      font-size: 16px; }
    .ClientAdditional__head__name_container__subname--pilares {
      font-family: 'Montserrat';
      color: #4a4d68;
      line-height: 1.13;
      letter-spacing: -0.01px;
      font-size: 16px; }
    .ClientAdditional__head__name_container__subname--almagro {
      font-family: 'Montserrat';
      color: #4D4D4D !important;
      line-height: 1.13;
      letter-spacing: -0.01px;
      font-size: 16px; }

.ClientAdditional__descripcion--socovesa {
  width: 340px;
  height: 140px;
  font-family: 'HelveticaNeue';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: #4a4d68; }
  .ClientAdditional__descripcion--socovesa.__center {
    text-align: center; }
  .ClientAdditional__descripcion--socovesa.commnet-variant {
    letter-spacing: 0.48px;
    font-style: italic;
    color: #cbcbcb; }

.ClientAdditional__descripcion--almagro {
  width: 340px;
  height: 140px;
  font-family: 'HelveticaNeue';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: #4D4D4D !important; }
  .ClientAdditional__descripcion--almagro.__center {
    text-align: center; }
  .ClientAdditional__descripcion--almagro.commnet-variant {
    letter-spacing: 0.48px;
    font-style: italic;
    color: #cbcbcb; }

.ClientAdditional__descripcion--pilares {
  width: 340px;
  height: 140px;
  font-family: 'HelveticaNeue';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: #4a4d68; }
  .ClientAdditional__descripcion--pilares.__center {
    text-align: center; }
  .ClientAdditional__descripcion--pilares.commnet-variant {
    letter-spacing: 0.48px;
    font-style: italic;
    color: #cbcbcb; }

.ClientAdditional__data_container {
  display: grid; }

.ClientAdditional__data {
  font-family: 'HelveticaNeue';
  font-size: 10px;
  line-height: 1.8;
  letter-spacing: -0.01px;
  color: #bebfc5;
  margin-bottom: 7px; }

.ClientAdditional__data__detail--socovesa {
  line-height: 1.13;
  letter-spacing: -0.02px;
  color: #4a4d68;
  font-family: 'HelveticaNeue';
  font-size: 15px;
  margin-bottom: 7px; }

.ClientAdditional__data__detail--pilares {
  line-height: 1.13;
  letter-spacing: -0.02px;
  color: #4a4d68;
  font-family: 'HelveticaNeue';
  font-size: 15px;
  margin-bottom: 7px; }

.ClientAdditional__data__detail--almagro {
  line-height: 1.13;
  letter-spacing: -0.02px;
  color: #4D4D4D !important;
  font-family: 'HelveticaNeue';
  font-size: 15px;
  margin-bottom: 7px; }

.ClientAdditional__Line {
  height: 1px;
  background-color: #bebfc5;
  margin-left: 30px;
  margin-right: 30px; }

.ClientAdditional__contact {
  display: grid; }
  .ClientAdditional__contact__title {
    font-family: 'HelveticaNeue';
    letter-spacing: -0.02px;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold; }
    .ClientAdditional__contact__title--socovesa {
      color: #303446; }
    .ClientAdditional__contact__title--pilares {
      color: #303446; }
    .ClientAdditional__contact__title--almagro {
      color: #4D4D4D !important; }
  .ClientAdditional__contact__text {
    font-family: 'HelveticaNeue';
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.48px; }
    .ClientAdditional__contact__text--socovesa {
      color: #4a4d68; }
    .ClientAdditional__contact__text--pilares {
      color: #4a4d68; }
    .ClientAdditional__contact__text--almagro {
      color: #4D4D4D !important; }
  .ClientAdditional__contact__button {
    color: #ffffff !important;
    width: 40%;
    padding: 10px !important;
    font-weight: bold !important;
    margin-top: auto !important;
    margin-bottom: auto !important; }
    .ClientAdditional__contact__button--socovesa {
      background-color: #53308f !important; }
    .ClientAdditional__contact__button--almagro {
      background-color: #ee3424 !important; }
    .ClientAdditional__contact__button--pilares {
      background-color: #ff6900 !important; }
    .ClientAdditional__contact__button--empresas {
      background-color: #0093ca !important; }

.status-switch .Mui-checked {
  color: green; }

@media only screen and (max-width: 768px) {
  .ClientAdditional__head {
    padding: 106px 0 0;
    flex-direction: column;
    align-items: center; }
    .ClientAdditional__head__name_container {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .ClientAdditional__head__name_container__name {
        font-size: 1.5rem; }
        .ClientAdditional__head__name_container__name--almagro {
          color: #ff6900; }
        .ClientAdditional__head__name_container__name--socovesa {
          color: #53308f; }
        .ClientAdditional__head__name_container__name--pilares {
          color: #ff6900; }
      .ClientAdditional__head__name_container__subname {
        margin-top: 2px; }
  .ClientAdditional__contact {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .ClientAdditional__contact__title {
      text-align: left;
      align-self: flex-start; }
    .ClientAdditional__contact__button {
      width: 100%; }
  .ClientAdditional__desktop {
    display: none; }
  .ClientAdditional__mobile {
    display: block; } }

@media only screen and (min-width: 768px) {
  .ClientAdditional__desktop {
    display: flex; }
  .ClientAdditional__mobile {
    display: none; } }

.selector .MuiSelect-select.MuiSelect-selectMenu {
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem;
  gap: 0.25rem; }

.AdditionalList {
  width: 100%;
  border-radius: 5px;
  box-shadow: none;
  color: white; }
  .AdditionalList__container {
    display: grid;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px; }
  .AdditionalList__expansionPanelDetails {
    border-radius: 5px;
    width: 100%;
    padding: 10px 0px; }

.AdditionalDetail {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px; }
  .AdditionalDetail__expansionPanelSummary {
    display: inline-grid; }
    .AdditionalDetail__expansionPanelSummary__estado-solicitud {
      display: inline; }
    .AdditionalDetail__expansionPanelSummary__solicitud-data {
      display: flex;
      flex-direction: column; }
      .AdditionalDetail__expansionPanelSummary__solicitud-data__one {
        display: grid;
        grid-template-columns: 3fr 1fr; }
    .AdditionalDetail__expansionPanelSummary__barra-tareas {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin-top: 0.75rem; }
      .AdditionalDetail__expansionPanelSummary__barra-tareas .warning {
        color: red; }
    .AdditionalDetail__expansionPanelSummary__addressName {
      font-size: 12px;
      color: #8e8f96;
      margin-bottom: 6px;
      font-weight: bold;
      margin-left: 34px;
      text-transform: none !important; }
    .AdditionalDetail__expansionPanelSummary__AddName {
      font-size: 14px;
      font-weight: bolder;
      text-transform: uppercase;
      margin-bottom: 5px;
      display: flex;
      align-items: flex-end; }
      .AdditionalDetail__expansionPanelSummary__AddName span {
        font-size: 12px;
        color: #8e8f96;
        font-weight: normal;
        margin-left: 5px; }
      .AdditionalDetail__expansionPanelSummary__AddName__ProyD {
        display: inline-block; }
      .AdditionalDetail__expansionPanelSummary__AddName__ProyM {
        display: none; }
      .AdditionalDetail__expansionPanelSummary__AddName__Nro {
        display: inline-block; }
    .AdditionalDetail__expansionPanelSummary__Icono {
      margin-right: 10px; }
  .AdditionalDetail__expansionPanelDetails {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 16px 32px 0px; }
    .AdditionalDetail__expansionPanelDetails__botones {
      margin-top: 0.75rem;
      display: flex;
      justify-content: space-between; }
    .AdditionalDetail__expansionPanelDetails__table {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr 4fr 3fr 2fr 5fr 2fr;
      width: 100%;
      border-style: none; }
      .AdditionalDetail__expansionPanelDetails__table__row {
        display: table-row;
        border: #b7aec8 1px solid;
        border-collapse: collapse;
        border-style: inset;
        border-spacing: 0px;
        width: 100%; }
        .AdditionalDetail__expansionPanelDetails__table__row__header {
          display: table-cell;
          padding: 5px;
          border: #b7aec8 1px solid;
          font-weight: bolder;
          border-collapse: collapse;
          border-style: inset;
          border-spacing: 0px;
          font-size: 12px; }
        .AdditionalDetail__expansionPanelDetails__table__row__cell {
          display: table-cell;
          padding: 5px;
          border: #b7aec8 1px solid;
          border-collapse: collapse;
          border-style: inset;
          border-spacing: 0px;
          vertical-align: middle;
          font-size: 12px;
          color: #8e8f96; }
          .AdditionalDetail__expansionPanelDetails__table__row__cell__icon {
            cursor: pointer; }
    .AdditionalDetail__expansionPanelDetails__desktop {
      display: flex; }
    .AdditionalDetail__expansionPanelDetails__mobile {
      display: none;
      flex-direction: column; }
      .AdditionalDetail__expansionPanelDetails__mobile__requirement {
        border: 1px solid;
        padding: 10px;
        margin: 10px;
        border-radius: 5px; }
      .AdditionalDetail__expansionPanelDetails__mobile__details {
        margin: 0 auto;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 5px;
        gap: 5px; }
        .AdditionalDetail__expansionPanelDetails__mobile__details__icon {
          cursor: pointer; }
        .AdditionalDetail__expansionPanelDetails__mobile__details > span {
          display: flex;
          align-items: center; }
      .AdditionalDetail__expansionPanelDetails__mobile__units {
        display: flex;
        flex-direction: column;
        padding-top: 0.8rem;
        align-items: flex-end; }
    .AdditionalDetail__expansionPanelDetails__document {
      position: absolute;
      top: 25px;
      right: 56px; }
      .AdditionalDetail__expansionPanelDetails__document__content {
        cursor: pointer; }
        .AdditionalDetail__expansionPanelDetails__document__content a {
          text-decoration: none; }
        .AdditionalDetail__expansionPanelDetails__document__content a:hover {
          text-decoration: underline; }
  .AdditionalDetail__check {
    position: absolute;
    right: 60px;
    top: 28px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center; }
    .AdditionalDetail__check > span {
      padding-right: 15px;
      display: inline-block; }
  .AdditionalDetail__newRequest {
    padding: 2rem; }
    .AdditionalDetail__newRequest__buttons {
      display: flex;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      @media only screen and (max-width: 768px) {
        .AdditionalDetail__newRequest__buttons {
          flex-direction: column;
          width: 100%; } }
      .AdditionalDetail__newRequest__buttons button {
        color: #ffffff; }
        .AdditionalDetail__newRequest__buttons button.danger {
          background-color: #c70000; }
        .AdditionalDetail__newRequest__buttons button.primary {
          background-color: #3085d6; }
  .AdditionalDetail__noAdditional {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 0.8rem; }

.white--text {
  color: #fff; }

.additional-card-almagro {
  background-color: #FFFFFF !important;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  margin-bottom: 20px;
  color: #4D4D4D !important; }

.ClientAdditional__container--socovesa {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }

.ClientAdditional__container--almagro {
  background-color: #F8F8F8 !important;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }

.ClientAdditional__container--pilares {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }

.ClientAdditional__head {
  display: flex;
  justify-content: space-between; }
  .ClientAdditional__head ._button_action {
    max-width: 300px;
    width: 40%; }
  .ClientAdditional__head__name_container {
    display: grid;
    margin-left: 20px; }
    .ClientAdditional__head__name_container__name--socovesa {
      color: #53308f;
      font-size: 32px;
      letter-spacing: -0.03px;
      margin-top: 20px;
      text-transform: capitalize; }
    .ClientAdditional__head__name_container__name--pilares {
      color: #ff6900;
      font-size: 32px;
      letter-spacing: -0.03px;
      margin-top: 20px;
      text-transform: capitalize; }
    .ClientAdditional__head__name_container__name--almagro {
      color: #4D4D4D !important;
      font-size: 32px;
      letter-spacing: -0.03px;
      margin-top: 20px;
      text-transform: capitalize; }
    .ClientAdditional__head__name_container__subname--socovesa {
      font-family: 'Montserrat';
      color: #4a4d68;
      line-height: 1.13;
      letter-spacing: -0.01px;
      font-size: 16px;
      margin-top: -10px; }
    .ClientAdditional__head__name_container__subname--pilares {
      font-family: 'Montserrat';
      color: #4a4d68;
      line-height: 1.13;
      letter-spacing: -0.01px;
      font-size: 16px;
      margin-top: -10px; }
    .ClientAdditional__head__name_container__subname--almagro {
      font-family: 'Montserrat';
      color: #4D4D4D !important;
      line-height: 1.13;
      letter-spacing: -0.01px;
      font-size: 16px;
      margin-top: -10px; }

.ClientAdditional__descripcion--socovesa {
  width: 340px;
  height: 140px;
  font-family: 'HelveticaNeue';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: #4a4d68; }
  .ClientAdditional__descripcion--socovesa.__center {
    text-align: center; }
  .ClientAdditional__descripcion--socovesa.commnet-variant {
    letter-spacing: 0.48px;
    font-style: italic;
    color: #cbcbcb; }

.ClientAdditional__descripcion--almagro {
  width: 340px;
  height: 140px;
  font-family: 'HelveticaNeue';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: #4D4D4D !important; }
  .ClientAdditional__descripcion--almagro.__center {
    text-align: center; }
  .ClientAdditional__descripcion--almagro.commnet-variant {
    letter-spacing: 0.48px;
    font-style: italic;
    color: #cbcbcb; }

.ClientAdditional__descripcion--pilares {
  width: 340px;
  height: 140px;
  font-family: 'HelveticaNeue';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.48px;
  color: #4a4d68; }
  .ClientAdditional__descripcion--pilares.__center {
    text-align: center; }
  .ClientAdditional__descripcion--pilares.commnet-variant {
    letter-spacing: 0.48px;
    font-style: italic;
    color: #cbcbcb; }

.ClientAdditional__data_container {
  display: grid; }

.ClientAdditional__data {
  font-family: 'HelveticaNeue';
  font-size: 10px;
  line-height: 1.8;
  letter-spacing: -0.01px;
  color: #bebfc5;
  margin-bottom: 7px; }

.ClientAdditional__data__detail--socovesa {
  line-height: 1.13;
  letter-spacing: -0.02px;
  color: #4a4d68;
  font-family: 'HelveticaNeue';
  font-size: 15px;
  margin-bottom: 7px; }

.ClientAdditional__data__detail--pilares {
  line-height: 1.13;
  letter-spacing: -0.02px;
  color: #4a4d68;
  font-family: 'HelveticaNeue';
  font-size: 15px;
  margin-bottom: 7px; }

.ClientAdditional__data__detail--almagro {
  line-height: 1.13;
  letter-spacing: -0.02px;
  color: #4D4D4D !important;
  font-family: 'HelveticaNeue';
  font-size: 15px;
  margin-bottom: 7px; }

.ClientAdditional__Line {
  height: 1px;
  background-color: #bebfc5;
  margin-left: 30px;
  margin-right: 30px; }

.ClientAdditional__contact {
  display: grid; }
  .ClientAdditional__contact__title {
    font-family: 'HelveticaNeue';
    letter-spacing: -0.02px;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold; }
    .ClientAdditional__contact__title--socovesa {
      color: #303446; }
    .ClientAdditional__contact__title--pilares {
      color: #303446; }
    .ClientAdditional__contact__title--almagro {
      color: #4D4D4D !important; }
  .ClientAdditional__contact__text {
    font-family: 'HelveticaNeue';
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.48px; }
    .ClientAdditional__contact__text--socovesa {
      color: #4a4d68; }
    .ClientAdditional__contact__text--pilares {
      color: #4a4d68; }
    .ClientAdditional__contact__text--almagro {
      color: #4D4D4D !important; }
  .ClientAdditional__contact__button {
    color: #ffffff !important;
    width: 40%;
    padding: 10px !important;
    font-weight: bold !important;
    margin-top: auto !important;
    margin-bottom: auto !important; }
    .ClientAdditional__contact__button--socovesa {
      background-color: #53308f !important; }
    .ClientAdditional__contact__button--almagro {
      background-color: #ee3424 !important; }
    .ClientAdditional__contact__button--pilares {
      background-color: #ff6900 !important; }

.status-switch .Mui-checked {
  color: green; }

@media only screen and (max-width: 768px) {
  .ClientAdditional__head {
    padding: 106px 0 0;
    flex-direction: column;
    align-items: center; }
    .ClientAdditional__head__name_container {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .ClientAdditional__head__name_container__name {
        font-size: 1.5rem; }
        .ClientAdditional__head__name_container__name--almagro {
          color: #ff6900; }
        .ClientAdditional__head__name_container__name--socovesa {
          color: #53308f; }
        .ClientAdditional__head__name_container__name--pilares {
          color: #ff6900; }
      .ClientAdditional__head__name_container__subname {
        margin-top: 2px; }
    .ClientAdditional__head ._button_action {
      width: 100%;
      text-decoration: none; }
  .ClientAdditional__contact {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .ClientAdditional__contact__title {
      text-align: left;
      align-self: flex-start; }
  .ClientAdditional__desktop {
    display: none; }
  .ClientAdditional__mobile {
    display: block; }
  .ActionDetail {
    padding: 0; } }

@media only screen and (min-width: 768px) {
  .ClientAdditional__desktop {
    display: flex; }
  .ClientAdditional__mobile {
    display: none; } }

.selector .MuiSelect-select.MuiSelect-selectMenu {
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem;
  gap: 0.25rem; }

.UnitList {
  width: 100%;
  border-radius: 5px;
  box-shadow: none;
  color: white; }
  .UnitList__container {
    display: grid;
    align-items: center;
    padding: 0px 20px;
    border-radius: 5px;
    grid-template-columns: 1fr;
    justify-content: start; }
    .UnitList__container__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.2rem;
      font-weight: bold;
      margin: 10px 0px; }
      .UnitList__container__header__title-pilares {
        color: #ff6900; }
      .UnitList__container__header__title-socovesa {
        color: #53308f; }
      .UnitList__container__header__title-almagro {
        color: #4D4D4D !important; }
    .UnitList__container__unit {
      display: inline-block;
      text-transform: capitalize; }
    .UnitList__container__body {
      margin: 5px 0px;
      display: grid;
      grid-template-columns: 40% 60%;
      grid-auto-flow: row;
      align-items: center; }
      @media only screen and (max-width: 960px) {
        .UnitList__container__body {
          flex-direction: column;
          grid-template-columns: 100%;
          width: 100%; }
          .UnitList__container__body__address {
            display: none; } }
      .UnitList__container__body --almagro {
        background-color: #F8F8F8 !important;
        color: #4D4D4D !important; }
    .UnitList__container__cardContainer {
      padding: 0px 46px;
      display: flex;
      flex-direction: column;
      grid-gap: 5px;
      gap: 5px; }
  .UnitList__expansionPanelDetails {
    border-radius: 5px;
    width: 100%;
    padding: 10px 0px; }

.EditProfile__container {
  width: auto;
  margin: 2rem auto;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); }
  .EditProfile__container .EditProfile__head {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 30px;
    grid-gap: 15px;
    gap: 15px; }
    .EditProfile__container .EditProfile__head__avatarLabel {
      padding: 15px;
      border-radius: 50%; }
    .EditProfile__container .EditProfile__head__name_container {
      text-align: center;
      font-size: 32px;
      font-weight: 400; }
      .EditProfile__container .EditProfile__head__name_container__name--almagro {
        color: #4D4D4D; }
      .EditProfile__container .EditProfile__head__name_container__name--pilares {
        color: #ff6900; }
  .EditProfile__container .EditProfile__button {
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px !important;
    text-transform: none !important; }
    .EditProfile__container .EditProfile__button--socovesa {
      color: white;
      background-color: #53308f !important; }
    .EditProfile__container .EditProfile__button--almagro {
      color: white;
      background-color: #ee3424 !important; }
    .EditProfile__container .EditProfile__button--pilares {
      color: white;
      background-color: #ff6900 !important; }
    .EditProfile__container .EditProfile__button__actualizar--socovesa {
      background-color: #EFF3F8;
      color: #acacac; }
    .EditProfile__container .EditProfile__button__actualizar--almagro {
      background-color: #F8F8F8;
      color: #acacac; }
    .EditProfile__container .EditProfile__button__actualizar--pilares {
      background-color: #EDEDEF;
      color: #acacac; }
  .EditProfile__container .EditProfile__data_container {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem; }
    .EditProfile__container .EditProfile__data_container .EditProfile__data .MuiInputBase-root {
      background: #f9f9f9;
      border-radius: 5px; }

@media (max-width: 768px) {
  .EditProfile__container {
    padding: 20px;
    margin: 1rem auto;
    box-shadow: none; }
  .EditProfile__head {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px; }
  .EditProfile__head__avatarLabel {
    padding: 5px; }
  .EditProfile__head__name_container {
    font-size: 1rem; }
  .EditProfile__button {
    width: 100%;
    padding: 10px;
    font-size: 0.875rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, -webkit-transform 0.2s ease;
    transition: background-color 0.3s ease, transform 0.2s ease;
    transition: background-color 0.3s ease, transform 0.2s ease, -webkit-transform 0.2s ease; }
    .EditProfile__button:hover {
      -webkit-transform: translateY(-2px);
              transform: translateY(-2px); }
    .EditProfile__button--socovesa {
      background-color: #53308f; }
    .EditProfile__button--almagro {
      background-color: #ee3424; }
    .EditProfile__button--pilares {
      background-color: #ff6900; }
    .EditProfile__button__actualizar--socovesa {
      background-color: #EFF3F8; }
    .EditProfile__button__actualizar--almagro {
      background-color: #F8F8F8; }
    .EditProfile__button__actualizar--pilares {
      background-color: #EDEDEF; }
  .EditProfile__data_container {
    grid-gap: 0.5rem;
    gap: 0.5rem; }
  .EditProfile__data .MuiInputBase-root {
    font-size: 0.8rem; } }

@media (max-width: 1024px) {
  .EditProfile__container {
    padding: 25px;
    margin: 1.5rem auto; }
  .EditProfile__head {
    grid-gap: 10px;
    gap: 10px; }
  .EditProfile__head__name_container {
    font-size: 1.5rem; }
  .MuiButton-contained {
    padding: 5px 10px;
    font-size: 0.5rem; } }

.ClientPropertyDetail__greeting {
  font-size: 18px;
  letter-spacing: -0.02px; }
  .ClientPropertyDetail__greeting--socovesa {
    color: #acacac; }
  .ClientPropertyDetail__greeting--almagro {
    color: #acacac; }
  .ClientPropertyDetail__greeting--pilares {
    color: #acacac; }

.ClientPropertyDetail__detail {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 15px 0;
  width: 45%;
  border-radius: 15px; }
  .ClientPropertyDetail__detail__item {
    display: flex;
    flex-direction: column;
    margin: 0px 0 10px; }
    .ClientPropertyDetail__detail__item__name {
      font-weight: bold; }
  .ClientPropertyDetail__detail--almagro {
    background-color: #F8F8F8;
    color: black; }
  .ClientPropertyDetail__detail--socovesa {
    background-color: #ffffff; }
  .ClientPropertyDetail__detail--pilares {
    background-color: #ffffff; }

.ClientPropertyDetail__ProjectName--almagro {
  color: #ffffff !important; }

@media only screen and (max-width: 768px) {
  .ClientPropertyDetail {
    padding: 100px 15px;
    max-width: 95vw; }
    .ClientPropertyDetail__detail {
      width: 90%; }
      .ClientPropertyDetail__detail__item {
        display: block;
        padding: 5px; }
        .ClientPropertyDetail__detail__item > span {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px; } }

.ClientLayout {
  top: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  position: absolute; }
  .ClientLayout--socovesa {
    background-color: #EFF3F8; }
  .ClientLayout--almagro {
    background-color: #FFFFFF; }
  .ClientLayout--pilares {
    background-color: #EDEDEF; }
  .ClientLayout__sideMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; }
    .ClientLayout__sideMenu--socovesa {
      color: #ffffff;
      background-color: #415364; }
    .ClientLayout__sideMenu--almagro {
      color: #ffffff;
      background-color: #F8F8F8; }
    .ClientLayout__sideMenu--pilares {
      color: #ffffff;
      background-color: #33383c; }
    .ClientLayout__sideMenu__logo {
      margin: 60px 0 0; }
    .ClientLayout__sideMenu__Menu {
      margin: 100px 0 0; }
  .ClientLayout__body {
    padding: 25px 30px;
    background-color: inherit; }

@media only screen and (max-width: 959px) {
  .ClientLayout__Menu {
    display: none; }
  .ClientLayout__body {
    padding: 0px; } }

.dialog-view {
  border: #8e8f96 1px solid; }

.dialog-new {
  border: #8e8f96 1px solid; }

.ProjectList {
  width: 100%;
  border-radius: 5px;
  box-shadow: none;
  color: white; }
  .ProjectList__container {
    display: grid;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px; }
  .ProjectList__expansionPanelDetails {
    border-radius: 5px;
    width: 100%;
    padding: 10px 0px; }

.ProjectDetailPV {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  padding: 0px 30px; }
  .ProjectDetailPV__expansionPanelSummary {
    display: inline-grid; }
    .ProjectDetailPV__expansionPanelSummary__estado-solicitud {
      display: inline; }
    .ProjectDetailPV__expansionPanelSummary__solicitud-data {
      display: flex;
      flex-direction: column; }
      .ProjectDetailPV__expansionPanelSummary__solicitud-data__one {
        display: grid;
        grid-template-columns: 3fr 1fr; }
    .ProjectDetailPV__expansionPanelSummary__barra-tareas {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin-top: 0.75rem; }
      .ProjectDetailPV__expansionPanelSummary__barra-tareas .warning {
        color: red; }
    .ProjectDetailPV__expansionPanelSummary__addressName {
      font-size: 12px;
      color: #8e8f96;
      margin-bottom: 6px;
      font-weight: bold;
      margin-left: 34px;
      text-transform: none !important; }
    .ProjectDetailPV__expansionPanelSummary__UnitName {
      font-size: 14px;
      font-weight: bolder;
      text-transform: uppercase;
      margin-bottom: 5px;
      display: flex;
      align-items: flex-end; }
      .ProjectDetailPV__expansionPanelSummary__UnitName span {
        font-size: 12px;
        color: black;
        font-weight: normal;
        margin-left: 5px; }
      .ProjectDetailPV__expansionPanelSummary__UnitName__ProyD {
        display: inline-block; }
      .ProjectDetailPV__expansionPanelSummary__UnitName__ProyM {
        display: none; }
      .ProjectDetailPV__expansionPanelSummary__UnitName__Nro {
        display: inline-block; }
    .ProjectDetailPV__expansionPanelSummary__Icono {
      margin-right: 10px; }
  .ProjectDetailPV__expansionPanelDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 32px 0px; }
    .ProjectDetailPV__expansionPanelDetails__botones {
      margin-top: 0.75rem;
      display: flex;
      justify-content: space-between; }
    .ProjectDetailPV__expansionPanelDetails__table {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr 2fr 2fr 2fr 5fr 2fr;
      width: 100%;
      border-style: none; }
      .ProjectDetailPV__expansionPanelDetails__table__row {
        display: table-row;
        border: #b7aec8 1px solid;
        border-collapse: collapse;
        border-style: inset;
        border-spacing: 0px;
        width: 100%; }
        .ProjectDetailPV__expansionPanelDetails__table__row__header {
          display: table-cell;
          padding: 5px;
          border: #b7aec8 1px solid;
          font-weight: bolder;
          border-collapse: collapse;
          border-style: inset;
          border-spacing: 0px;
          font-size: 12px; }
        .ProjectDetailPV__expansionPanelDetails__table__row__cell {
          display: table-cell;
          padding: 5px;
          border: #b7aec8 1px solid;
          border-collapse: collapse;
          border-style: inset;
          border-spacing: 0px;
          vertical-align: middle;
          font-size: 12px;
          color: #8e8f96;
          word-break: break-word;
          white-space: pre-line; }
          .ProjectDetailPV__expansionPanelDetails__table__row__cell__icon {
            cursor: pointer; }
    .ProjectDetailPV__expansionPanelDetails__desktop {
      display: flex; }
    .ProjectDetailPV__expansionPanelDetails__mobile {
      display: none;
      flex-direction: column; }
      .ProjectDetailPV__expansionPanelDetails__mobile__requirement {
        border: 1px solid;
        padding: 10px;
        margin: 10px;
        border-radius: 5px; }
      .ProjectDetailPV__expansionPanelDetails__mobile__details {
        margin: 0 auto;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 5px;
        gap: 5px; }
        .ProjectDetailPV__expansionPanelDetails__mobile__details__icon {
          cursor: pointer; }
        .ProjectDetailPV__expansionPanelDetails__mobile__details > span {
          display: flex;
          align-items: center; }
    .ProjectDetailPV__expansionPanelDetails__documents {
      position: absolute;
      top: 10px;
      right: 56px; }
      @media (max-width: 450px) {
        .ProjectDetailPV__expansionPanelDetails__documents {
          top: 10px;
          right: 30px; } }
    .ProjectDetailPV__expansionPanelDetails__document__content {
      cursor: pointer;
      height: 100%; }
      .ProjectDetailPV__expansionPanelDetails__document__content a {
        text-decoration: none; }
        @media (max-width: 450px) {
          .ProjectDetailPV__expansionPanelDetails__document__content a {
            max-width: 120px;
            text-wrap-mode: wrap;
            font-size: 8px;
            font-weight: normal; } }
      .ProjectDetailPV__expansionPanelDetails__document__content a:hover {
        text-shadow: 0 0 1px #8e8f96; }
        @media (max-width: 450px) {
          .ProjectDetailPV__expansionPanelDetails__document__content a:hover {
            text-shadow: none; } }
  .ProjectDetailPV__check {
    position: absolute;
    right: 60px;
    top: 28px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center; }
    .ProjectDetailPV__check > span {
      padding-right: 15px;
      display: inline-block; }
  .ProjectDetailPV__newRequest {
    padding: 2rem; }
    .ProjectDetailPV__newRequest__buttons {
      display: flex;
      grid-gap: 0.5rem;
      gap: 0.5rem; }
      @media only screen and (max-width: 768px) {
        .ProjectDetailPV__newRequest__buttons {
          flex-direction: column;
          width: 100%; } }
      .ProjectDetailPV__newRequest__buttons button {
        color: #ffffff; }
        .ProjectDetailPV__newRequest__buttons button.danger {
          background-color: #c70000; }
        .ProjectDetailPV__newRequest__buttons button.primary {
          background-color: #3085d6; }

.capitalize {
  text-transform: capitalize; }

.UnitDetail {
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 0 0 10px; }
  .UnitDetail--almagro {
    background-color: #FFFFFF !important;
    color: #4D4D4D !important; }
  .UnitDetail--pilares {
    background-color: #000000;
    color: #ffffff; }
  .UnitDetail--socovesa {
    background-color: #000000;
    color: #ffffff; }
  .UnitDetail__property {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .UnitDetail__property__label {
      font-size: 0.8rem; }
    .UnitDetail__property__value {
      margin-left: 20px; }

@media only screen and (max-width: 768px) {
  .ProjectTable__desktop {
    display: none; }
  .ProjectTable__mobile {
    display: flex;
    flex-direction: column; }
  __footer {
    padding: 23px 0px;
    margin-left: auto; }
    __footer__addMoreLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
  .ProjectList__container {
    width: 95vw;
    padding: 100px 10px; }
  .ProjectDetailPV {
    max-width: 125%;
    padding: 0px; }
    .ProjectDetailPV__check > span {
      display: none; }
    .ProjectDetailPV__expansionPanelSummary__UnitName {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between; }
      .ProjectDetailPV__expansionPanelSummary__UnitName__Nro {
        display: none; }
      .ProjectDetailPV__expansionPanelSummary__UnitName__ProyD {
        display: none; }
      .ProjectDetailPV__expansionPanelSummary__UnitName__ProyM {
        display: inline-block; }
    .ProjectDetailPV__expansionPanelSummary__barra-tareas {
      flex-direction: column-reverse; }
    .ProjectDetailPV__expansionPanelDetails__mobile {
      display: flex; }
    .ProjectDetailPV__expansionPanelDetails__desktop {
      display: none; } }

.send-request-title {
  font-weight: bold; }

.RequirementView {
  display: flex;
  flex-direction: row;
  width: 45em;
  background-color: inherit;
  color: inherit;
  align-items: center; }
  .RequirementView__header {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: -20px; }
    .RequirementView__header__title {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bolder;
      text-align: center;
      padding-bottom: 3px;
      width: 85%;
      border-bottom: 1px solid;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .RequirementView__header__title__req {
        display: flex; }
      .RequirementView__header__title__est {
        display: flex;
        flex-direction: row;
        grid-gap: 10px;
        gap: 10px; }
      .RequirementView__header__title__mid {
        color: #333333; }

.requirement-view__content {
  font-size: 12px;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 16px; }
  .requirement-view__content > p {
    display: grid;
    word-break: break-word;
    white-space: pre-line; }
    .requirement-view__content > p > strong {
      margin-right: 10px; }
    .requirement-view__content > p > span {
      display: inline-flex;
      text-align: justify; }

.requirement-view__close-button {
  position: absolute;
  top: 0;
  right: 0; }

.image-view {
  width: 100%;
  height: 280px;
  border: #b7aec8 1px solid;
  position: relative;
  margin-left: 16px;
  margin-top: 16px;
  margin-right: 8px; }
  .image-view img {
    width: 360px;
    height: 280px; }
  .image-view__image {
    max-width: inherit;
    max-height: inherit;
    opacity: 1; }
    .image-view__image__img {
      width: 360px;
      height: 280px;
      opacity: 1; }
    .image-view__image__left-arrow {
      position: absolute;
      top: 40%;
      left: 0; }
    .image-view__image__right-arrow {
      position: absolute;
      top: 40%;
      right: 0; }
    .image-view__image__zoom-button {
      position: absolute;
      right: 0;
      bottom: 0; }

@media only screen and (max-width: 768px) {
  .image-view {
    margin: 5px;
    width: 300px;
    height: 233px; }
    .image-view img {
      width: 300px;
      height: 233px; }
  .requirement-view__content {
    width: 95%; }
    .requirement-view__content > p {
      grid-template-columns: 1fr 2fr; }
  .RequirementView {
    flex-direction: column-reverse;
    width: 100%; }
    .RequirementView__header__title {
      flex-direction: column;
      width: 95%; }
      .RequirementView__header__title__est {
        display: flex;
        flex-direction: row-reverse;
        grid-gap: 10px;
        gap: 10px; }
      .RequirementView__header__title__req {
        width: 80%; } }

.Solicitud {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5em;
  gap: 0.5em; }
  .Solicitud__fecha_contenedor {
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em; }
  .Solicitud__fecha {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .Solicitud__fecha > span {
      border: 1px solid #fff;
      padding: 0.5em;
      border-radius: 1%; }
  .Solicitud__garantias {
    display: flex;
    width: 92%;
    grid-gap: 1em;
    gap: 1em;
    justify-content: center;
    border-top: 1px solid;
    margin: 1rem 2rem;
    border-bottom: 1px solid;
    padding: 0.5rem 0rem; }
    .Solicitud__garantias > span {
      width: 100%;
      text-align: center;
      padding-bottom: .3em; }
  .Solicitud__buttons {
    display: flex;
    justify-content: flex-end;
    grid-gap: 1em;
    gap: 1em;
    margin-top: 1em; }
  .Solicitud__titulo {
    display: flex;
    width: 90%;
    justify-content: center;
    position: absolute;
    top: 0.7em;
    margin-left: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid; }
    .Solicitud__titulo > span {
      width: 100%;
      text-align: center;
      padding-bottom: .3em; }
  .Solicitud__requerimiento {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1em; }
    .Solicitud__requerimiento__imagen {
      margin: 1em;
      background-color: black;
      border: 1px solid white;
      border-radius: 1%;
      width: 368px;
      height: 275px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .Solicitud__requerimiento__imagen__img {
        width: 368px;
        height: 275px; }
      .Solicitud__requerimiento__imagen > label {
        padding: 0;
        line-height: 0; }
      .Solicitud__requerimiento__imagen__button__hidden {
        display: none; }
      .Solicitud__requerimiento__imagen > picture {
        background-color: white; }
    .Solicitud__requerimiento__formulario {
      display: flex;
      flex-direction: column;
      grid-gap: .25em;
      gap: .25em; }
      .Solicitud__requerimiento__formulario__input {
        margin-bottom: 1em; }

.MuiFormControl-root {
  margin: 0 5em;
  width: 25em; }

.MuiInput-root > svg {
  color: rgba(255, 255, 255, 0.54); }

@media only screen and (max-width: 768px) {
  .Solicitud {
    font-size: 0.8em; }
    .Solicitud__garantias {
      flex-direction: column;
      grid-gap: 1em;
      gap: 1em; }
      .Solicitud__garantias > div {
        width: 100%; }
        .Solicitud__garantias > div > strong {
          width: 45%; }
    .Solicitud__titulo {
      width: 80%; }
    .Solicitud__fecha {
      width: 100%; }
    .Solicitud__requerimiento {
      grid-template-columns: 1fr; }
      .Solicitud__requerimiento__formulario__input {
        font-size: 0.8em; }
        .Solicitud__requerimiento__formulario__input > label {
          padding-top: 0.7em;
          font-size: 1.5em; }
      .Solicitud__requerimiento__imagen {
        position: inherit;
        display: flex;
        width: 70vw;
        height: 200px;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-bottom: 1em; }
        .Solicitud__requerimiento__imagen > label {
          position: inherit;
          line-height: 0; }
        .Solicitud__requerimiento__imagen__img {
          width: 70vw;
          height: 200px; }
        .Solicitud__requerimiento__imagen > img {
          width: 70vw;
          height: 200px; }
  .MuiInputBase-root {
    font-size: 1em; }
  .MuiSelect-select.MuiSelect-select {
    font-size: .7em; }
  .MuiInputLabel-formControl {
    font-size: 1.2em; } }

.add-more-container {
  display: flex;
  justify-content: end; }

.warning {
  color: red; }

.ClientActivation {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  font-family: 'Montserrat';
  height: 100%; }
  .ClientActivation--socovesa {
    background-color: #ffffff; }
    .ClientActivation--socovesa h1 {
      color: #53308f; }
  .ClientActivation--almagro {
    color: #ffffff;
    background-color: #F8F8F8; }
    .ClientActivation--almagro h1 {
      color: #E52713; }
  .ClientActivation--pilares {
    background-color: #ffffff; }
    .ClientActivation--pilares h1 {
      color: #ff6900; }

.ActivationMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  border-radius: 4px; }
  .ActivationMessage__logo {
    margin: 40px 0 0; }

.LoginContainer {
  box-sizing: border-box;
  height: 100vh;
  overflow: auto; }
  .LoginContainer--socovesa {
    border-bottom: 10px solid #53308f; }
  .LoginContainer--empresas {
    border-bottom: 10px solid #0093ca; }
  .LoginContainer--pilares {
    border-bottom: 10px solid #ff6900; }
  .LoginContainer--almagro {
    border-bottom: 8px solid #ee3424; }
  .LoginContainer__container {
    box-sizing: border-box;
    height: calc(100vh - 12px); }

.ClientStatus {
  display: flex;
  flex-direction: row; }
  .ClientStatus__errorMessage {
    font-style: italic; }

.button-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-flow: row wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem; }

.ProjectTableItem {
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  background-color: var(--invalid-name);
  font-family: 'Source Sans Pro';
  white-space: nowrap; }
  .ProjectTableItem__responsive {
    display: none; }
  .ProjectTableItem__column {
    padding: 25px 20px;
    margin: 0 0 10px;
    text-align: center;
    min-width: 100px;
    text-wrap: wrap; }
    .ProjectTableItem__column__center {
      text-align: center; }
    .ProjectTableItem__column__circle {
      font-size: 12px;
      background-color: #0093ca;
      color: #ffffff;
      padding: 4px 9px;
      border-radius: 50%; }
  .ProjectTableItem__progress {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px; }
    .ProjectTableItem__progress > * {
      margin-right: 30px; }
    .ProjectTableItem__progress__button {
      justify-content: flex-end; }

@media only screen and (max-width: 768px) {
  .ProjectTableItem {
    display: none; }
    .ProjectTableItem__responsive {
      display: flex;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
      background-color: #ffffff;
      margin: 0 0 20px; }
      .ProjectTableItem__responsive__container {
        display: flex;
        flex-direction: column;
        width: 100%; }
      .ProjectTableItem__responsive__itemContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between; }
        .ProjectTableItem__responsive__itemContainer:not(:last-child) {
          border-bottom: solid 1px #ededef; }
      .ProjectTableItem__responsive__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 10px 0px 20px 10px; }
        .ProjectTableItem__responsive__item--fullSize {
          align-items: flex-start; }
        .ProjectTableItem__responsive__item__button {
          position: initial; }
        .ProjectTableItem__responsive__item__label {
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.01px;
          color: #bebfc5; } }

.AlphabeticalFilter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 30px; }
  .AlphabeticalFilter__letter {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.02px;
    color: #003a63;
    padding: 7.5px; }
    .AlphabeticalFilter__letter--empresas--active {
      color: #0093ca;
      border-bottom: 2px solid #0093ca; }

@media only screen and (max-width: 768px) {
  .AlphabeticalFilter {
    flex-direction: column;
    position: absolute;
    right: 0px; } }

.ProjectTable {
  display: flex;
  flex-direction: column;
  flex-basis: 100%; }
  .ProjectTable__mobile {
    display: none; }
  .ProjectTable__header {
    display: flex;
    flex-direction: row;
    align-items: left;
    margin: 0 0 15px; }
  .ProjectTable__body__table {
    table-layout: auto;
    white-space: nowrap;
    border-spacing: 15px;
    white-space: normal;
    word-wrap: break-word; }
    .ProjectTable__body__table__header {
      padding: 0px 0px 15px 0px;
      width: 150px;
      text-align: center;
      color: #cacaca; }
      .ProjectTable__body__table__header__item {
        padding: 0px 0px 15px 30px;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: -0.01px;
        color: #a7a7a7;
        text-align: left;
        text-transform: uppercase; }
        .ProjectTable__body__table__header__item:nth-child(-n+3) {
          padding: 0px 0px 20px 15px; }
        .ProjectTable__body__table__header__item__center {
          text-align: center; }
  .ProjectTable__body__emptyMessage {
    padding: 30px;
    text-align: center;
    color: #696666;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }
  .ProjectTable__footer {
    padding: 23px 0px;
    margin-left: auto; }
    .ProjectTable__footer__addMoreLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .ProjectTable__footer__addMoreLabel__icon {
        margin-left: 7px; }

@media only screen and (max-width: 768px) {
  .ProjectTable__desktop {
    display: none; }
  .ProjectTable__mobile {
    display: flex;
    flex-direction: column;
    width: 90vw; }
  __footer {
    padding: 23px 0px;
    margin-left: auto; }
    __footer__addMoreLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; } }

.ExecutiveSelector {
  max-width: 220px;
  position: absolute;
  right: 50px;
  top: 0; }
  .ExecutiveSelector__label {
    color: #003a63; }

.EnterpriseHome {
  height: 100%; }
  .EnterpriseHome__container {
    padding: 25px 30px;
    position: relative; }
    .EnterpriseHome__container__title {
      color: #003a63;
      font-size: 32px;
      font-family: Montserrat;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.03px;
      color: #003a63;
      text-transform: uppercase; }
    .EnterpriseHome__container__messageLabel {
      display: block;
      color: #acacac;
      font-size: 18px;
      letter-spacing: -0.02px;
      margin: 0 0 10px; }
    .EnterpriseHome__container__messages {
      width: 100%; }
    .EnterpriseHome__container__subtitle {
      margin: 55px 0 20px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.02px; }
    .EnterpriseHome__container__table {
      margin: 30px 0 0; }

@media only screen and (max-width: 768px) {
  .EnterpriseHome__container {
    padding: 80px 15px 20px; }
    .EnterpriseHome__container__executiveSelector {
      margin: 0 auto 50px;
      position: initial;
      max-width: 80%; } }


.EnterpriseMessageList {
  padding: 25px 30px; }
  .EnterpriseMessageList__title {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0 0 25px; }
  .EnterpriseMessageList__searchbar {
    margin: 0 0 20px; }
  .EnterpriseMessageList__tabs {
    margin: 0 0 30px; }
  .EnterpriseMessageList__item {
    margin: 0 0 15px; }
  .EnterpriseMessageList__addMoreRows {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .EnterpriseMessageList__addMoreRows__label {
      display: flex;
      align-items: center; }
    .EnterpriseMessageList__addMoreRows__icon {
      display: flex;
      align-items: center;
      margin-left: 5px; }

@media only screen and (max-width: 768px) {
  .EnterpriseMessageList {
    padding: 100px 10px; } }

.ExecutiveListItem {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  position: relative;
  display: flex;
  padding: 15px 0;
  margin-bottom: 10px; }
  .ExecutiveListItem .item {
    flex: 3 1;
    font-size: 16px;
    margin-right: 100px;
    color: #303446;
    flex-grow: 2; }
    .ExecutiveListItem .item:nth-of-type(1) {
      padding-left: 44px;
      flex-grow: 5; }
  .ExecutiveListItem .ProjectButton__wrapper.ProjectItem__header__button {
    cursor: pointer;
    position: static;
    right: inherit;
    top: inherit;
    width: 50px;
    margin-right: 20px;
    color: #003a63; }
  .ExecutiveListItem__body__image {
    width: 100%; }
  .ExecutiveListItem__footer {
    padding: 20px; }
    .ExecutiveListItem__footer__label {
      display: block;
      margin: 0px 0px 10px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.01px; }

.ExecutiveList {
  height: 100%;
  width: 100%;
  box-sizing: border-box; }

.ExecutiveList > * {
  font-family: 'Source Sans Pro';
  text-transform: uppercase; }

.EnterpriseExecutiveList {
  padding: 30px; }
  .EnterpriseExecutiveList__title {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0 0 25px; }

.PermissionDialog {
  background-color: #f4f4f8;
  padding: 20px;
  border-radius: 10px; }
  .PermissionDialog__title {
    color: #333;
    font-size: 24px;
    margin-bottom: 5px;
    text-align: center; }
  .PermissionDialog__subtitle {
    color: #666;
    font-size: 18px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-align: center; }

.ProfilePermissions,
.OtherPermissions {
  margin-bottom: 20px;
  padding: 20px; }
  .ProfilePermissions__title,
  .OtherPermissions__title {
    color: #444;
    font-size: 16px;
    margin-bottom: 15px;
    padding-top: 0.5rem;
    margin-top: 0%; }
  .ProfilePermissions__checkbox,
  .OtherPermissions__checkbox {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 10px;
    gap: 10px; }
  .ProfilePermissions .permissionCheckbox,
  .OtherPermissions .permissionCheckbox {
    accent-color: #007bff; }

.ProfilePermissions {
  background-color: #e8eaf6;
  border-radius: 8px; }
  .ProfilePermissions__title {
    color: #202853; }

.OtherPermissions {
  background-color: #f0f0f4;
  border-radius: 8px; }



.ClientList {
  padding: 25px 30px; }
  .ClientList__title {
    font-family: Montserrat;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    text-transform: uppercase; }

@media only screen and (max-width: 768px) {
  .ClientList {
    padding: 100px 30px 0px 10px; } }

.SubsidiaryCardItem {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  background-color: var(--invalid-name);
  white-space: nowrap; }
  .SubsidiaryCardItem__title {
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0 0 25px; }
  .SubsidiaryCardItem__checkbox {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    padding-left: 10px;
    padding-bottom: 10px; }
  .SubsidiaryCardItem__responsive {
    display: none; }
  .SubsidiaryCardItem__saveButton {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: -0.02px; }
    .SubsidiaryCardItem__saveButton--almagro {
      color: #ffffff; }
    .SubsidiaryCardItem__saveButton--socovesa {
      color: #000000; }
    .SubsidiaryCardItem__saveButton--pilares {
      color: #000000; }

.SubsidiaryScreenList {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  padding: 30px; }
  .SubsidiaryScreenList__title {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0 0 25px;
    padding-bottom: 20px; }

.RolesCardItem {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4); }
  .RolesCardItem__header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .RolesCardItem__footer {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    padding-top: 20px; }
  .RolesCardItem__title {
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0;
    flex: 1 1; }
  .RolesCardItem__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    gap: 10px;
    padding-top: 10px; }
  .RolesCardItem__checkbox {
    display: flex;
    flex-direction: row;
    flex-grow: 10;
    grid-gap: 10px;
    gap: 10px;
    padding-left: 10px;
    padding-bottom: 10px; }
  .RolesCardItem__responsive {
    display: none; }

.RolesScreenList {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  padding: 30px; }
  .RolesScreenList__title {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0 0 25px;
    padding-bottom: 20px; }

.doc-classification-card {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4); }
  .doc-classification-card .header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .doc-classification-card .footer {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    padding-top: 20px; }
  .doc-classification-card .title {
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0;
    flex: 1 1; }
  .doc-classification-card .content {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px; }
  .doc-classification-card .checkbox {
    display: flex;
    flex-direction: row;
    flex-grow: 10;
    grid-gap: 10px;
    gap: 10px;
    padding-left: 10px;
    padding-bottom: 10px; }
  .doc-classification-card .responsive {
    display: none; }

.DocumentsClassification {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  padding: 30px; }
  .DocumentsClassification__title {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0 0 25px;
    padding-bottom: 20px; }
  .DocumentsClassification__container {
    width: 100%;
    height: 100%;
    padding: 30px;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }

.MultipleUploadItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  border: solid 1px #b7aec8;
  padding: 15px 25px;
  font-size: 12px; }
  .MultipleUploadItem:not(:first-child) {
    margin-top: 10px; }
  .MultipleUploadItem__icon {
    margin-right: 15px; }
  .MultipleUploadItem__name {
    font-weight: bold;
    text-transform: uppercase; }

.MultipleUpload {
  padding: 20px 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }
  .MultipleUpload__dialog {
    padding: 30px; }
    .MultipleUpload__dialog__action {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 30px auto; }
  .MultipleUpload__input {
    padding: 10px; }
  .MultipleUpload__title {
    padding: 0px 10px 20px; }
  .MultipleUpload__submitButton {
    display: flex;
    justify-content: space-around;
    padding: 30px 0 15px; }

.MultipleUploadList {
  margin: 20px 0 0;
  padding: 22px 21px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }
  .MultipleUploadList__list {
    list-style-type: none;
    padding: 0; }

.ImageUpload {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  background-color: #ffffff;
  padding: 20px; }
  .ImageUpload__uploadButton {
    display: flex !important;
    width: 90% !important;
    margin: auto !important;
    align-self: center !important; }
  .ImageUpload__action {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%; }
    .ImageUpload__action > * {
      margin-left: 20px !important; }
  .ImageUpload__preview {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 24px; }
    .ImageUpload__preview__img {
      border-radius: 8px; }
    .ImageUpload__preview__description {
      align-self: flex-start;
      margin: 8px 0; }
  .ImageUpload__listContainer {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
    background-color: inherit;
    padding: 15px 20px;
    margin: 20px 0; }
    .ImageUpload__listContainer__list {
      padding: 0;
      margin: 15px 0;
      list-style: none; }
      .ImageUpload__listContainer__list__item {
        display: flex;
        flex-direction: row;
        border-radius: 5px;
        border: solid 1px #b7aec8;
        background-color: inherit;
        align-items: center; }
        .ImageUpload__listContainer__list__item:not(:last-child) {
          margin: 0 0 20px; }
        .ImageUpload__listContainer__list__item__img {
          width: 60px;
          height: 60px; }

@media only screen and (max-width: 768px) {
  .ImageUpload .MuiFormControl-root {
    margin: 0 0 20px; } }

.ProjectDetail {
  padding: 25px 30px; }
  .ProjectDetail__projectName {
    color: #003a63 !important; }
  .ProjectDetail__expansionPanelSummary {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .ProjectDetail__expansionPanelSummary__check {
      position: absolute;
      right: 50px;
      top: 12px; }
  .ProjectDetail__title {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.02px;
    margin: 43px 0 25px; }

.MilestoneCalendarForm {
  display: flex;
  flex-direction: column;
  padding: 13px 20px;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }
  .MilestoneCalendarForm__container {
    display: flex;
    flex-direction: row; }
  .MilestoneCalendarForm__form__body {
    padding: 0px 40px 40px 40px; }
  .MilestoneCalendarForm__form__input {
    width: 100%; }
  .MilestoneCalendarForm__form__label {
    color: #676977; }
  .MilestoneCalendarForm__form__buttons {
    display: flex !important;
    justify-content: space-between !important; }
  .MilestoneCalendarForm__form__button {
    margin-left: 15px !important; }
  .MilestoneCalendarForm__items_head {
    padding: 10px 50px;
    margin-bottom: 30px;
    border-bottom: solid rgba(0, 0, 0, 0.2) 1px;
    text-align: center; }
  .MilestoneCalendarForm__arrows {
    display: flex;
    align-items: center;
    color: inherit !important;
    text-transform: inherit !important;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: -0.02px;
    text-align: right; }
  .MilestoneCalendarForm__footer {
    padding: 23px 0px;
    margin-left: auto;
    float: right; }
    .MilestoneCalendarForm__footer__addMoreLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .MilestoneCalendarForm__footer__addMoreLabel__icon {
        margin-left: 7px; }

@media only screen and (max-width: 768px) {
  .MilestoneCalendarForm__items_head {
    padding: 10px; }
  .MilestoneCalendarForm__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
  .MilestoneCalendarForm__form__buttons {
    display: flex;
    align-items: center;
    flex-direction: column-reverse; }
    .MilestoneCalendarForm__form__buttons .MuiButtonBase-root {
      width: 100%;
      margin: 0 0 20px; }
  .MilestoneCalendarForm__form__body {
    padding: 0; }
    .MilestoneCalendarForm__form__body .MuiGrid-spacing-xs-10 {
      padding: 10px; } }

.MilestoneCalendar {
  display: flex;
  flex-direction: column;
  padding: 13px 20px;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }
  .MilestoneCalendar__container {
    display: flex;
    flex-direction: row; }
  .MilestoneCalendar__scrollable {
    height: 150px;
    overflow-y: scroll; }
  .MilestoneCalendar__items_head {
    padding: 10px 40px 30px 0px;
    margin-bottom: 30px;
    border-bottom: solid rgba(0, 0, 0, 0.2) 1px;
    text-align: center; }
    .MilestoneCalendar__items_head__item {
      padding: 10px !important; }
  .MilestoneCalendar__items {
    margin-top: 10px;
    border-radius: 3px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    text-align: center; }
    .MilestoneCalendar__items__doc_text {
      text-transform: uppercase; }
    .MilestoneCalendar__items__cols {
      text-align: left;
      padding-left: 60px; }
  .MilestoneCalendar__arrows {
    display: flex;
    align-items: center;
    color: inherit !important;
    text-transform: inherit !important;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: -0.02px;
    text-align: right; }
  .MilestoneCalendar__footer {
    padding: 23px 0px;
    margin-left: auto;
    display: flex;
    justify-content: end; }
    .MilestoneCalendar__footer__addMoreLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .MilestoneCalendar__footer__addMoreLabel__icon {
        margin-left: 7px; }

.NoteItem {
  display: flex;
  flex-direction: column;
  padding: 13px 20px;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }
  .NoteItem__container {
    display: flex;
    flex-direction: row; }
    .NoteItem__container__info {
      display: flex;
      flex-direction: column;
      flex-basis: 50%; }
      .NoteItem__container__info__date {
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: -0.02px;
        margin: 0 0 5px; }
      .NoteItem__container__info__title {
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.02px;
        color: #1f4b7e; }
    .NoteItem__container__buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end; }
      .NoteItem__container__buttons__withlabel {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .NoteItem__container__buttons button {
        display: flex !important;
        margin: 0 10px 0; }
        .NoteItem__container__buttons button:last-child {
          display: flex !important;
          margin: 0 0 0 10px; }
      .NoteItem__container__buttons__arrowButton {
        color: #0093ca; }
        .NoteItem__container__buttons__arrowButton--active {
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }
  .NoteItem__collapse {
    margin: 13px 0 0;
    overflow: scroll;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.02px;
    color: #383b50; }

.NoteList {
  display: flex;
  flex-direction: column; }
  .NoteList__container {
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }
    .NoteList__container__list {
      margin: 0;
      list-style-type: none;
      overflow-y: auto;
      padding: 0;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }
  .NoteList__form {
    padding: 10px 30px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }
    .NoteList__form__buttons {
      display: flex !important;
      justify-content: space-between; }
  .NoteList__buttonContainer {
    margin: 20px 0 0 auto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.02px; }
    .NoteList__buttonContainer__button {
      display: flex;
      align-items: center; }
      .NoteList__buttonContainer__button__component {
        margin-left: 10px; }

@media only screen and (max-width: 768px) {
  .NoteList__container {
    width: 100%; }
  .NoteList__form__buttons {
    flex-direction: column; }
    .NoteList__form__buttons .MuiButtonBase-root {
      margin: 0 0 20px; } }

.ClientDetail {
  padding: 25px 30px; }
  .ClientDetail__title {
    font-size: 32px;
    font-family: Montserrat;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: -0.03px;
    color: #003a63;
    margin: 0; }
  .ClientDetail__subtitle {
    margin: 0 0 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.02px;
    text-transform: uppercase;
    color: #acacac; }
  .ClientDetail__info {
    padding: 36px 30px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4); }
    .ClientDetail__info__title {
      margin: 0 0 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.02px;
      text-align: left;
      color: #003a63;
      text-transform: uppercase; }
    .ClientDetail__info__list {
      list-style-type: none;
      text-align: left;
      padding: 0; }
      .ClientDetail__info__list__label {
        line-height: 1.29;
        letter-spacing: -0.02px;
        color: #bebfc5; }
      .ClientDetail__info__list__value {
        margin: 0 0 10px; }
      .ClientDetail__info__list__errorMessage {
        font-style: italic; }
  .ClientDetail .ClientProjects, .ClientDetail .ClientProperties, .ClientDetail .ClientDocuments {
    width: 100%; }
    .ClientDetail .ClientProjects .ClientProject, .ClientDetail .ClientProperties .ClientProject, .ClientDetail .ClientDocuments .ClientProject, .ClientDetail .ClientProperties .ClientProperty, .ClientDetail .ClientDocuments .ClientProperty {
      box-shadow: 'none';
      margin-bottom: 1rem;
      border-radius: 5px; }
  .ClientDetail .ClientProperties, .ClientDetail .ClientDocuments {
    width: 100%; }
    .ClientDetail .ClientProperties .filter, .ClientDetail .ClientDocuments .filter {
      display: flex;
      justify-content: end; }
  .ClientDetail .MuiExpansionPanelSummary-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
  .ClientDetail .BusinessStatusButton {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
    position: relative;
    padding: 15px 35px !important;
    margin: 0px 0 0px 20px !important; }
    .ClientDetail .BusinessStatusButton:hover g {
      fill: white; }

@media only screen and (max-width: 768px) {
  .ClientDetail {
    padding: 100px 15px; }
    .ClientDetail .MuiGrid-spacing-xs-6 {
      width: auto;
      margin: 0; } }

.EnterpriseLayout {
  top: 0;
  bottom: 0;
  position: absolute;
  height: 100%;
  margin: 0;
  overflow: auto; }
  .EnterpriseLayout__sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #003a63;
    min-height: 100%; }
    .EnterpriseLayout__sidebar__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 60px 0 0; }
      .EnterpriseLayout__sidebar__container__logo {
        margin: auto auto 100px; }
  .EnterpriseLayout__body {
    position: relative; }

@media only screen and (max-width: 768px) {
  .EnterpriseLayout__sidebar {
    display: none; } }

