.DocumentList {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  
  &__header {
    margin: 0 0 28px;
  }
  
  &__uploadButton {
    width: 30%!important;
    margin-left: auto!important;
    margin-top: 15px!important;
  }
  
  &__list {
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(103, 105, 119, 0.4);
  }
  
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 35px 0 0 auto;
    
    &__select {
      flex-basis: 40%;
      margin-right: 30px!important;
    }
  }
  
  &__dialogModal {
    padding: 20px!important;
    
    &__title {
      padding: 20px;
    }
    
    &__content {
      padding: 20px;
    }
    
    &__actions {
      padding: 10px;
      
      &__button {
        width: 250px!important;
      }
    }
  }

  /* Estilos para Accordion */
  .DocumentList__group {
    transition: all 0.3s ease; /* Transición suave para el acordeón */
  }

  /* Estilos para AccordionSummary */
  .DocumentList__title {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #f1f1f1; /* Fondo para el título */
    font-weight: bold;
  }

  .DocumentList__arrow {
    margin-left: 10px;
  }

  /* Estilos para AccordionDetails */
  .DocumentList__documents {
    display: block;
    visibility: visible;
    padding: 15px; /* Añade padding para un mejor espaciado */
    overflow: auto; /* Evita que el contenido se desborde */
    width: 100%; /* Asegura que ocupe todo el ancho disponible */
  }

  /* Estilos para el contenedor de los DocumentItem */
  &__details {
    display: flex;
    width: 98%;
    flex-direction: column; /* Coloca cada item en una nueva línea */
    margin-bottom: 10px; /* Espaciado entre cada item */
  }

  /* Asegura que DocumentItem ocupe todo el ancho */
  .DocumentItem {
    width: 100%; /* Cada DocumentItem ocupa todo el ancho */
    margin-bottom: 15px; /* Espaciado entre DocumentItems */
  }
}
